import React from "react";
import PropTypes from "prop-types";

const ProgressStep = ({ step, index, length }) => {
  return (
    <div className="card theme-2">
      <div className="step-numbers">
        <h3 className="headline-xxl">{index + 1}</h3>
        <span className="headline-l number-length">/{length}</span>
      </div>
      <div className="body">
        {step.entity.fieldTitle && (
          <p className="step-title">
            <strong>{step.entity.fieldTitle}</strong>
          </p>
        )}
        {step.entity.fieldText?.processed && (
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: step.entity.fieldText.processed,
            }}
          />
        )}
      </div>
    </div>
  );
};

ProgressStep.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.shape({
    entity: PropTypes.shape({
      fieldTitle: PropTypes.string,
      fieldText: PropTypes.shape({
        processed: PropTypes.string,
      }),
    }),
  }),
  length: PropTypes.number.isRequired,
};

export default ProgressStep;
