import React from "react";

const WuebbenSignet = () => {
  return (
    <svg
      width="48"
      height="55"
      viewBox="0 0 48 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.1515 53.9119L0.699013 12.2029C0.579773 11.3715 0.758633 10.4801 2.04472 10.2058L8.58588 8.80861C9.13949 8.68861 9.62497 8.91147 9.74421 9.54576L14.3264 36.2974C14.4457 36.8632 15.1781 36.7089 15.3059 36.0917L19.8881 7.3686C20.0073 6.62288 20.5013 6.25431 21.0464 6.1343L27.281 4.79715C27.8346 4.67715 28.3201 4.84001 28.4393 5.5343L33.0811 32.2774C33.2004 32.8431 33.9329 32.6888 34.0606 32.0717L38.5917 3.37428C38.711 2.68856 39.205 2.25998 39.7501 2.13998L46.2912 0.734255C47.5773 0.459967 47.7562 1.27426 47.6369 2.15712L40.1759 47.0632C40.0567 47.749 39.5627 48.1775 39.0176 48.2975L29.9724 50.2347C29.4188 50.3547 28.9333 50.1318 28.8141 49.4976L24.6577 25.6088C24.5385 24.9745 23.806 25.1373 23.6782 25.8145L19.4623 51.4947C19.343 52.1804 18.849 52.609 18.3039 52.729L9.31835 54.649C8.76474 54.769 8.27926 54.5462 8.16002 53.9119H8.1515Z"
        fill="black"
      />
    </svg>
  );
};

export default WuebbenSignet;
