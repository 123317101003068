import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { LinkPropType } from "../../../link/link";
import { Link } from "react-router-dom";
import { self } from "../../../../config";

const LinkWrapper = ({ link, children }) => {
  if (!link) {
    return <>{children}</>;
  }

  if (link.routed === true) {
    return <Link to={link.path}>{children}</Link>;
  }

  if (link.routed === false) {
    return <a href={link.path}>{children}</a>;
  }
};

const ParagraphCallToAction = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-call-to-action": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <LinkWrapper link={content.fieldCallToAction.url}>
              <div
                className={`card ${content.fieldTheme || "theme-grey"} hover`}
              >
                <div className="cta-content-wrapper">
                  {content.fieldSmallTitle && (
                    <p className="small-title">{content.fieldSmallTitle}</p>
                  )}
                  <h2 className="headline-xl">
                    {content.fieldCallToAction.title}
                  </h2>
                </div>
                <div className="cta-icon-wrapper">
                  <img src={self + "/icon-link-external.svg"} />
                </div>
              </div>
            </LinkWrapper>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphCallToAction.propTypes = {
  content: PropTypes.shape({
    fieldCallToAction: LinkPropType,
    fieldSmallTitle: PropTypes.string,
    fieldTheme: PropTypes.string,
  }),
};

export default ParagraphCallToAction;
