import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import ErrorBoundary from "../../../../../error-boundary";
import { useLazyQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import teaserNodeQueryFilterTag from "../../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import TeaserMedia, {
  teaserMediaPropTypes,
} from "../../../../teaser-base/media/teaser-media";
import { encodeUrl } from "../../../../../lib/encode-url";
import MediaOverlay from "../../../../../media/components/media-overlay";
import { FormattedMessage } from "react-intl";
import { AnimatePresence } from "framer-motion";

const ParagraphTeaserListMedia = ({ content }) => {
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0); // offset variable for the request
  const [nodes, setNodes] = useState(
    content.fieldMode === "manual"
      ? content.fieldManualNodes.map((item) => item.entity)
      : []
  ); // state to store the nodes
  const [totalCount, setTotalCount] = useState(0); // total number of results
  const [overlayId, setOverlayId] = useState(""); // active node title URL-encoded
  const activeNode = nodes.filter(
    (node) => encodeUrl(node.title) === overlayId
  )[0]; // active node data extracted from overlayId and nodes

  // get the current language from redux store
  const { currentLanguage } = useSelector((reduxStore) => ({
    currentLanguage: reduxStore.i18n.currentLanguage,
  }));

  // store the lazy query function in getNodes to asynchronously fetch nodes
  const [getNodes] = useLazyQuery(teaserNodeQueryFilterTag, {
    skip: content.fieldMode === "manual",
    variables: {
      limit: content.fieldLimit || 100,
      type: ["media"],
      tag: content.fieldSchlagwort.map((item) => item.targetId),
      filterTagEnabled: !!content.fieldSchlagwort.length,
      language: currentLanguage.toUpperCase(),
      sortField: "title",
      sortDirection: "ASC",
    },
  });

  // trigger the getNodes function on every offset change
  useEffect(() => {
    if (content.fieldMode === "manual") return;

    setLoading(true);
    getNodes({ variables: { offset } })
      .then(({ data }) => {
        setNodes((prevNodes) => [...prevNodes, ...data.nodeQuery.entities]);
        setTotalCount(data.nodeQuery.count);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [offset]);

  // change the offset to trigger another request
  const loadMoreHandler = (e) => {
    e.preventDefault();
    setOffset((prevOffset) => prevOffset + content.fieldLimit);
  };

  // variables to reduce/simplify the JSX
  const loadMoreButtonEnabled =
    content.fieldLoadMore && totalCount > nodes.length;

  const activateOverlay = (mediumId) => {
    setOverlayId(mediumId);
    //document?.body.classList.add("scroll-disabled");
    const urlParams = new URLSearchParams("?medium=" + mediumId);
    window.history.replaceState(
      {},
      "",
      window.location.pathname + "?" + urlParams
    );
  };

  const closeOverlay = () => {
    setOverlayId("");
    //document?.body.classList.remove("scroll-disabled");
    window.history.replaceState(
      {},
      "",
      window.location.pathname
    );
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setOverlayId(urlParams.get("medium"));
  }, []);

  return (
    <section className="paragraph paragraph-teaser-list paragraph-teaser-list-media">
      <div className="container">
        <div className="teaser-grid">
          {!!nodes.length &&
            nodes.map((item) => (
              <div key={encodeUrl(item.title)} className="teaser-grid-cell">
                <ErrorBoundary>
                  <TeaserMedia item={item} onClick={activateOverlay} />
                </ErrorBoundary>
              </div>
            ))}
        </div>
        <div className="load-more-wrapper">
          {loadMoreButtonEnabled && (
            <button
              className={loading ? "loading" : ""}
              onClick={loadMoreHandler}
            >
              {loading && (
                <img className="spinner" src="/loader-spinner.svg" alt="" />
              )}
              <FormattedMessage id="load_more" />
            </button>
          )}
        </div>
      </div>
      {typeof window !== "undefined" && document.getElementById("overlay-wrapper") && ReactDOM.createPortal(
        <AnimatePresence>
          {overlayId && activeNode && (
            <MediaOverlay
              entityUrl={activeNode.entityUrl.path}
              onClose={closeOverlay}
            />
          )}
        </AnimatePresence>,
        document.getElementById("overlay-wrapper")
      )}
    </section>
  );
};

ParagraphTeaserListMedia.propTypes = {
  content: PropTypes.shape({
    fieldMode: PropTypes.string.isRequired,
    fieldLimit: PropTypes.number,
    fieldLoadMore: PropTypes.bool,
    fieldManualNodes: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape(teaserMediaPropTypes),
      })
    ),
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityId: PropTypes.number,
          name: PropTypes.string,
        }),
      })
    ),
  }),
};

export default ParagraphTeaserListMedia;
