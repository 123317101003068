import React from "react";
import PropTypes from "prop-types";
import SimpleImage from "../../../image/simple-image";

const ParagraphIntroC = ({ content }) => {
  return (
    <section className="paragraph paragraph-intro-c">
      <div className="intro-c-main">
        <div className="image-wrapper">
          <SimpleImage
            src={content.fieldImage.entity.fieldMediaImage.style.url}
            alt={content.fieldImage.entity.fieldMediaImage.alt}
            credit={content.fieldImage.entity.fieldCredit}
          />
        </div>
        <div className="container claims-wrapper">
          <div className="claim pinned theme-1">{content.fieldMainClaim}</div>
          {content.fieldSecondaryClaim && (
            <div
              className={`claim animated ${
                content.fieldTheme || "theme-1-light"
              }`}
            >
              {content.fieldSecondaryClaim}
            </div>
          )}
        </div>
      </div>
      <div className="intro-c-sub">
        {content.fieldAbstract && (
          <div className="container abstract">
            <div className="row">
              <div className="col-12 col-lg-9 text big">{content.fieldAbstract}</div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

ParagraphIntroC.propTypes = {
  content: PropTypes.shape({
    fieldMainClaim: PropTypes.string.isRequired,
    fieldSecondaryClaim: PropTypes.string,
    fieldTheme: PropTypes.string,
    fieldAbstract: PropTypes.string,
    fieldImage: PropTypes.object,
  }),
};

export default ParagraphIntroC;
