import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import IntroVariantA from "./variants/variant-a";
import IntroVariantB from "./variants/variant-b";

const ParagraphSimpleIntro = ({ content }) => {
  const title = {
    content: content.fieldTitle,
    classNames: classNames({
      "headline-l": content.fieldTitleMode !== "big",
      "headline-xl": content.fieldTitleMode === "big",
      quote: content.fieldTitleMode === "quote",
    }),
  };
  const abstract = content.fieldAbstract;
  const imageData = {
    ...content.fieldImage?.entity.fieldMediaImage,
    credit: content.fieldImage?.entity.fieldCredit,
    caption: content.fieldImage?.entity.fieldCaption
  };
  const tags = content.fieldSchlagwort;
  const theme = content.fieldTheme;

  switch (content.fieldDisplayMode) {
    case "variant-b":
      return (
        <IntroVariantB content={{ title, abstract, imageData, tags, theme }} />
      );
    case "variant-a":
    default:
      return (
        <IntroVariantA content={{ title, abstract, imageData, tags, theme }} />
      );
  }
};

ParagraphSimpleIntro.propTypes = {
  content: PropTypes.shape({
    fieldDisplayMode: PropTypes.oneOf([undefined, "variant-a", "variant-b"]),
    fieldTitle: PropTypes.string.isRequired,
    fieldTitleMode: PropTypes.string,
    fieldAbstract: PropTypes.string,
    fieldImage: PropTypes.object,
    fieldSchlagwort: PropTypes.object,
    fieldTheme: PropTypes.oneOf([
      "theme-1",
      "theme-1-light",
      "theme-2",
      "theme-2-light",
    ]),
  }),
};

export default ParagraphSimpleIntro;
