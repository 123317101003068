const adminAppAction = (adminApp) => ({
    adminApp,
    type: "ADMIN_APP",
  }),
  pagerFullPagesAction = (config) => ({
    config,
    type: "PAGER_FULL_PAGE",
  }),
  pagerFullPageContextAction = (id) => ({
    id,
    type: "PAGER_FULL_PAGE_CONTEXT_ID",
  }),
  initialLoadAction = (initialLoad) => ({
    initialLoad,
    type: "INITIAL_LOAD",
  });

export {
  adminAppAction,
  pagerFullPagesAction,
  pagerFullPageContextAction,
  initialLoadAction,
};
