import React from "react";
import PropTypes from "prop-types";
import teaserNodeQueryFilterTag from "../../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import TeaserFellow from "../../../../teaser-base/fellow/teaser-fellow";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";

const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  currentLanguage: reduxStore.i18n.currentLanguage,
});

const ParagraphTeaserListFellows = ({ nodes, manualNodes }) => {
  const formattedNodes =
    nodes?.nodeQuery?.entities ||
    manualNodes.map((fellow) => fellow.entity);

  return (
    <section className="paragraph paragraph-teaser-list paragraph-teaser-list-fellows">
      <div className="container">
        <div className="teaser-grid">
          {formattedNodes.map((node, i) => (
            <div key={i} className="teaser-grid-cell">
              <TeaserFellow content={node} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

ParagraphTeaserListFellows.propTypes = {
  content: PropTypes.shape({
    fieldAutomatischOderManuell: PropTypes.string,
    fieldAnzahlDerAnzuzeigendenI: PropTypes.any,
    fieldSchlagwort: PropTypes.array,
    fieldManualFellows: PropTypes.array,
  }),
};

export default connect(mapStateToProps)(graphql(teaserNodeQueryFilterTag, {
  name: "nodes",
  skip: (props) => props.nodesConfig === "Manuell",
  options: (props) => ({
    variables: {
      limit: props.count || 100,
      type: ["fellow"],
      tag: props.tags.map((item) => item.targetId),
      filterTagEnabled: !!props.tags.length,
      language: props.currentLanguage.toUpperCase(),
    },
  }),
})(ParagraphTeaserListFellows));

/*   const { data } = useQuery(teaserNodeQueryFilterTag, {
    skip: content.fieldAutomatischOderManuell === "Manuell",
    variables: {
      limit: content.fieldAnzahlDerAnzuzeigendenI || 100,
      type: ["fellow"],
      filterTagEnabled: !!content.fieldSchlagwort.length,
      tag: content.fieldSchlagwort,
      language: "DE",
    },
  }); */
