import React from "react";
import getLayoutPatterns from "../../../../lib/get-layout-patterns";
import Download from "./components/download";
import classNames from "classnames";
import PropTypes from "prop-types";

const ParagraphDownloadOverview = (props) => {
  // simplify data object and set title to either custom title or file name
  const downloads = props.content.fieldDownloadParagraphs.map((download) => ({
    title: download.entity.fieldTitle || download.entity.fieldDatei.entity.name,
    description: download.entity.fieldDescription,
    file: download.entity.fieldDatei.entity,
    preview: download.entity.fieldDatei.entity.fieldVorschaubild?.entity.fieldMediaImage,
    previewCredit: download.entity.fieldDatei.entity.fieldVorschaubild?.entity.fieldCredit,
    showPreview: download.entity.fieldShowPreviewImage,
    theme: download.entity.fieldTheme,
  }));

  const downloadPatterns = getLayoutPatterns(downloads);

  return (
    <section className="paragraph paragraph-download-overview">
      <div className="container">
        <div className="row">
          {downloadPatterns.map((downloadPattern, i) => (
            <React.Fragment key={i}>
              {downloadPattern.chunk.map((download, j) => (
                <div
                  key={j}
                  className={classNames({
                    "col-12 col-lg-6": downloadPattern.pattern[j] === 6,
                    "col-12 col-lg-4": downloadPattern.pattern[j] === 4 || downloadPattern.pattern[j] === 12,
                  })}
                >
                  <Download content={download} />
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

ParagraphDownloadOverview.propTypes = {
  content: PropTypes.shape({
    fieldDownloadParagraphs: PropTypes.array,
  }),
}

export default ParagraphDownloadOverview;
