/**
 * Transform an array into chunks and assign a layout pattern to each chunk.
 */
const getLayoutPatterns = (elements) => {
  const generateChunks = (array, chunkSize) => {
    const out = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      out.push(chunk);
    }
    return out;
  };

  // generate chunks from paragraphs (3 paragraphs/chunk)
  const paragraphChunks = generateChunks(elements, 3);

  // assign layout patterns as array to it (numbers are columns)
  const out = paragraphChunks.map((chunk, i) => {
    let pattern;
    switch (chunk.length) {
      case 3:
        pattern = [4, 4, 4];
        break;
      case 2:
        pattern = [6, 6];
        break;
      case 1:
        pattern = [12];
        break;
    }
    return { chunk, pattern };
  });

  return out;
};

export default getLayoutPatterns;
