import React from "react";
import PropTypes from "prop-types";

const IconPlay = ({ theme = "theme-grey" }) => {
  return (
    <svg
      width="68"
      height="58"
      viewBox="0 0 68 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon-play ${theme && theme}`}
    >
      <path
        id="Subtract"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.22656 0.140625C3.80828 0.140625 0.226562 3.72235 0.226562 8.14062V49.6538C0.226562 54.0721 3.80829 57.6538 8.22656 57.6538H59.2266C63.6448 57.6538 67.2266 54.0721 67.2266 49.6538V8.14062C67.2266 3.72235 63.6448 0.140625 59.2266 0.140625H8.22656ZM47.724 31.0273C49.3378 30.3338 49.3378 28.0458 47.724 27.3523L24.4537 17.3524C23.1337 16.7851 21.6641 17.7532 21.6641 19.1899V39.1897C21.6641 40.6264 23.1337 41.5945 24.4537 41.0272L47.724 31.0273Z"
        fill="#ffffff"
      />
    </svg>
  );
};

IconPlay.propTypes = {
  theme: PropTypes.string,
};

export default IconPlay;
