import React, { Component } from "react";
import PropTypes from "prop-types";

import TeaserNews from "./news/teaser-news";
import TeaserNewsTimeline from "./news/teaser-news-timeline";
import TeaserPerson from "./person/teaser-person";
import TeaserNewsTimelineList from "./news/teaser-news-timeline-list";
import TeaserPersonKontakt from "./person/teaser-person-kontakt";
import TeaserEventEinzelDoppel from "./event/teaser-event-einzel-doppel";
import TeaserEvent from "./event/teaser-event";
import TeaserEventTeaserlist from "./event/teaser-event-teaserlist";
import TeaserGeneral from "./general/teaser-general";
import TeaserPersonOverview from "./person/teaser-person-overview";
import TeaserPersonOverlay from "./person/teaser-person-overlay";
import TeaserProjekt from "./project/teaser-projekt";
import TeaserFellow from "./fellow/teaser-fellow";
import TeaserMedia from "./media/teaser-media";

class TeaserBase extends Component {
  render() {
    switch (this.props.item.entityBundle) {
      case "news":
        if (this.props.viewMode === "timeline") {
          return (
            <TeaserNewsTimeline
              item={this.props.item}
              pagerFullPage={this.props.pagerFullPage}
            />
          );
        }

        if (this.props.viewMode === "timeline-list") {
          return (
            <TeaserNewsTimelineList
              item={this.props.item}
              pagerFullPage={this.props.pagerFullPage}
            />
          );
        }

        return (
          <TeaserNews
            item={this.props.item}
            pagerFullPage={this.props.pagerFullPage}
          />
        );
      case "person":
        if (this.props.viewMode === "kontakt") {
          return (
            <TeaserPersonKontakt
              showImage={this.props.showImage}
              item={this.props.item}
              pagerFullPage={this.props.pagerFullPage}
            />
          );
        }
        if (this.props.viewMode === "overview") {
          return (
            <TeaserPersonOverview
              item={this.props.item}
              pagerFullPage={this.props.pagerFullPage}
            />
          );
        }
        if (this.props.viewMode === "overlay") {
          return (
            <TeaserPersonOverlay
              item={this.props.item}
              pagerFullPage={this.props.pagerFullPage}
            />
          );
        }

        return (
          <TeaserPerson
            item={this.props.item}
            pagerFullPage={this.props.pagerFullPage}
          />
        );
      case "veranstaltung":
        if (this.props.viewMode === "doppel-einzel") {
          return (
            <TeaserEventEinzelDoppel
              item={this.props.item}
              pagerFullPage={this.props.pagerFullPage}
              single={this.props.single}
            />
          );
        }

        if (this.props.viewMode === "teaserlist") {
          return (
            <TeaserEventTeaserlist
              item={this.props.item}
              pagerFullPage={this.props.pagerFullPage}
              single={this.props.single}
            />
          );
        }

        return (
          <TeaserEvent
            item={this.props.item}
            pagerFullPage={this.props.pagerFullPage}
          />
        );

      case "projekt":
        if (this.props.viewMode === "project") {
          return (
            <TeaserProjekt
              item={this.props.item}
              pagerFullPage={this.props.pagerFullPage}
              single={this.props.single}
            />
          );
        } else {
          return (
            <TeaserGeneral
              item={this.props.item}
              pagerFullPage={this.props.pagerFullPage}
            />
          );
        }

      case "fellow":
        return (
          <TeaserFellow
            content={this.props.item}
            pagerFullPage={this.props.pagerFullPage}
            single={this.props.single}
          />
        );

      case "media":
        return <TeaserMedia item={this.props.item} />;

      case "landingpage":
        return (
          <TeaserGeneral
            item={this.props.item}
            pagerFullPage={this.props.pagerFullPage}
          />
        );

      default:
        return null;
    }
  }
}

TeaserBase.propTypes = {
  item: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.bool,
  showImage: PropTypes.bool,
  single: PropTypes.bool,
  viewMode: PropTypes.string,
};

export default TeaserBase;
