import React from "react";
import ErrorBoundary from "../../../../../error-boundary";
import EmbeddedVideo from "./component-embedded";
import ComponentVideo from "./component-video";
import classNames from "classnames";
import PropTypes from "prop-types";

const Video = ({ content, theme }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-media": true,
  });

  switch (content.fieldVideoFileMedia.entity.entityBundle) {
    case "videolink":
      return (
        <ErrorBoundary>
          <EmbeddedVideo
            content={content}
            sectionClassNames={sectionClassNames}
            theme={theme}
          />
        </ErrorBoundary>
      );
    case "video":
      return (
        <ErrorBoundary>
          <ComponentVideo
            content={content}
            sectionClassNames={sectionClassNames}
            theme={theme}
          />
        </ErrorBoundary>
      );
    default:
      return false;
  }
};

Video.propTypes = {
  content: PropTypes.object.isRequired,
  theme: PropTypes.string,
};

export default Video;
