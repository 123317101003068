import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

/**
 * SEO module to render various meta tags
 * @see https://devhints.io/html-meta
 */
const ParagraphSEO = ({ content }) => {
  return (
    <>
      {/* Wrap each tag in its own Helmet component */}
      {content.fieldMetaDescription && (
        <Helmet>
          <meta
            name="description"
            content={content.fieldMetaDescription.value}
          />
        </Helmet>
      )}
      {content.fieldMetaImage && (
        <Helmet>
          <meta
            property="og:image"
            content={content.fieldMetaImage.entity.fieldMediaImage.style.url}
          />
        </Helmet>
      )}
    </>
  );
};

ParagraphSEO.propTypes = {
  content: PropTypes.shape({
    fieldMetaDescription: PropTypes.string,
    fieldMetaImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          style: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    }),
  }),
};

export default ParagraphSEO;
