import React from "react";

const DownloadIcon = () => {
  return (
    <svg
      width="51"
      height="55"
      viewBox="0 0 51 40"
      fill="none"
    >
      <path
        d="M8.02246 30.0563C7.4357 29.4727 7.43248 28.5241 8.01527 27.9365L9.46527 26.4746C10.0492 25.8858 11 25.8826 11.588 26.4672L22.6318 37.4496C22.9472 37.7632 23.4844 37.5398 23.4844 37.095L23.4844 1.5C23.4844 0.671572 24.1559 -9.18315e-07 24.9844 -8.82104e-07L27.1406 -7.87851e-07C27.9691 -7.51639e-07 28.6406 0.671572 28.6406 1.5L28.6406 37.0919C28.6406 37.5372 29.1787 37.7604 29.4938 37.4458L40.4971 26.4642C41.082 25.8804 42.029 25.8797 42.6148 26.4626L44.0959 27.9365C44.6852 28.523 44.6851 29.4769 44.0956 30.0632L27.1203 46.9478C26.5352 47.5298 25.5898 47.5298 25.0047 46.9478L8.02246 30.0563Z"
        fill="black"
        className="arrow"
      />
      <path
        d="M50 54L1 54"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DownloadIcon;
