import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import mediaQuery from "../media.graphql";
import ContentBase from "../../general-components/content-base/content-base";
import Error404 from "../../errors/404";
import Video from "../../general-components/content-base/paragraphs/media-video-audio/video-components";
import Error500 from "../../errors/500";
import SimpleImage from "../../general-components/image/simple-image";
import IconPlay from "../../general-components/teaser-base/media/components/icon-play";
import LoadingIndicator from "../../general-components/loading-indicator";
import { motion } from "framer-motion";

const MediaContent = ({ entityUrl }) => {
  const { data, loading, error } = useQuery(mediaQuery, {
    variables: {
      path: entityUrl,
    },
  });

  if (loading) return <LoadingIndicator />;
  if (error) return <Error500 error={error} />;
  if (data.route === null) <Error404 />;

  const nodeContent = data.route.entity;

  return (
    <motion.article
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`node-media node-${nodeContent.entityId} ${
        nodeContent.fieldTheme || "theme-2-light"
      }`}
    >
      <section className="paragraph">
        <div className="container">
          <h1>{nodeContent.title}</h1>
        </div>
      </section>
      <section className="paragraph">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 col-xl-9 offset-md-1 video-wrapper">
              {nodeContent.fieldVideoFileMedia && (
                /* reuse of the video components used in the video module */
                <Video content={nodeContent} theme={nodeContent.fieldTheme || "theme-grey"} />
              )}
              {nodeContent.fieldVideoUrl && (
                <a
                  href={nodeContent.fieldVideoUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SimpleImage
                    src={nodeContent.fieldBild.entity.fieldMediaImage.style.url}
                    alt={nodeContent.fieldBild.entity.fieldMediaImage.alt}
                  />
                  <IconPlay theme={nodeContent.fieldTheme} />
                </a>
              )}
            </div>
          </div>
        </div>
      </section>
      {nodeContent?.fieldModules && (
        <ContentBase content={nodeContent.fieldModules} />
      )}
    </motion.article>
  );
};

MediaContent.propTypes = {
  entityUrl: PropTypes.string.isRequired,
};

export default MediaContent;
