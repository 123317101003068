import React from "react";
import PropTypes from "prop-types";
import SimpleImage from "../../../../image/simple-image";

const IntroVariantA = ({ content }) => (
  <section
    className={`paragraph paragraph-simple-intro variant-a ${
      content.theme || "theme-1-light"
    }`}
  >
    <div className="content-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3 offset-lg-1">
            <SimpleImage
              src={
                content.imageData?.entity.filemime === "image/gif"
                  ? content.imageData.full
                  : content.imageData.style.url
              }
              alt={content.imageData?.alt}
              credit={content.imageData.credit}
            />
            {/* <div className="caption-wrap">{content.imageData.caption}</div> */}
          </div>
          <div className="col-12 col-md-6 offset-md-1 text-wrapper">
            {!!content.tags?.length && (
              <div className="tags-wrapper">
                {content.tags.map((tag, i) => (
                  <div className="tag" key={i}>
                    {tag.entity.entityLabel}
                  </div>
                ))}
              </div>
            )}
            <h1 className={content.title.classNames}>
              {content.title.content}
            </h1>
            <p>{content.abstract}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

IntroVariantA.propTypes = {
  content: PropTypes.object.isRequired,
};

export default IntroVariantA;
