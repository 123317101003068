import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import IconPlay from "../../../../teaser-base/media/components/icon-play";

const ComponentVideo = (props) => {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoRef = useRef();

  useEffect(() => {
    const video = videoRef.current;
    if (videoPlaying) video.play();
    if (!videoPlaying) video.pause();
  }, [videoPlaying]);

  const playPauseVideo = () => {
    setVideoPlaying((prev) => !prev);
  };

  const handleKeyDownPlayPauseButton = (event) => {
    switch (event.keyCode) {
      case 32: // Space
        playPauseVideo();
        break;
    }
  };

  // Keyboard Controlling of the Video Section
  const handleKeyDownVideo = (event) => {
    const video = event.target;

    switch (event.keyCode) {
      case 32: // Space
        playPauseVideo;
        break;
      case 37: // Left arrow
        video.currentTime -= 10;
        break;
      case 39: // Right arrow
        video.currentTime += 10;
        break;
      case 38: // Up arrow
        video.volume += 0.1;
        break;
      case 40: // Down arrow
        video.volume -= 0.1;
        break;
      case 77: // M
        if (video.muted) {
          video.muted = false;
        } else {
          video.muted = true;
        }
        break;
    }
  };

  const videoData = props.content.fieldVideoFileMedia?.entity;
  const thumbnail =
    videoData.fieldCustomThumbnail?.entity.fieldMediaImage.style.url || null;

  return (
    <div className="full-width local-video">
      {(props.content.fieldVideoFileMedia || props.content.fieldVideoUrl) && (
        <>
          <video
            src={
              props.content.fieldVideoFileMedia?.entity.fieldMediaVideoFile
                .entity.url || props.content.fieldVideoUrl
            }
            poster={thumbnail}
            tabIndex={0}
            controls={false}
            aria-controls="playpause"
            onKeyDown={handleKeyDownVideo}
            onEnded={() => setVideoPlaying(false)}
            onClick={playPauseVideo}
            ref={videoRef}
          />
          <div
            tabIndex={0}
            className="playpause"
            role="button"
            aria-label="Play or pause the video"
            onClick={() => playPauseVideo()}
            onKeyDown={handleKeyDownPlayPauseButton}
          >
            {!videoPlaying && <IconPlay theme={props.theme} />}
          </div>
        </>
      )}
    </div>
  );
};

ComponentVideo.propTypes = {
  theme: PropTypes.string,
  content: PropTypes.shape({
    /**
     * 0 = 100%, 1 = 50%
     */
    fieldGroesse: PropTypes.oneOf(["0", "1"]),
    fieldVideoFileMedia: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaVideoFile: PropTypes.shape({
          entity: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
        fieldCustomThumbnail: PropTypes.shape({
          entity: PropTypes.shape({
            fieldMediaImage: PropTypes.shape({
              style: PropTypes.shape({
                url: PropTypes.string,
              }),
              alt: PropTypes.string,
            }),
          }),
        }),
      }),
    }),
    fieldVideoUrl: PropTypes.string,
  }),
  sectionClassNames: PropTypes.string,
};

export default ComponentVideo;
