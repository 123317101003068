import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";

const ProgressSlider = React.forwardRef(
  ({ children, settings, onChange }, ref) => {
    children = children.flat().filter((e) => !!e);

    // default settings
    const { controls = true } = settings;

    // state
    const [currentSlide, setCurrentSlide] = useState(0);
    const [direction, setDirection] = useState("left");
    const [panThresholdCrossed, setPanThresholdCrossed] = useState(false);

    // ref
    const slidesRef = useRef([]);

    // handler
    const handleNext = () => {
      setDirection("left");
      setCurrentSlide((prevIndex) =>
        prevIndex + 1 < children.length ? prevIndex + 1 : prevIndex
      );
    };
    const handlePrevious = () => {
      setDirection("right");
      setCurrentSlide((prevIndex) =>
        prevIndex - 1 >= 0 ? prevIndex - 1 : prevIndex
      );
    };
    const handlePan = (e, pointInfo) => {
      if (!panThresholdCrossed && Math.abs(pointInfo.offset.x) > 50) {
        if (pointInfo.offset.x > 50) {
          handlePrevious();
        } else if (pointInfo.offset.x < -50) {
          handleNext();
        }
        setPanThresholdCrossed(true);
      }
    };

    useEffect(() => {
      onChange(currentSlide);
    }, [currentSlide]);

    // make handlers available in parent component
    useImperativeHandle(ref, () => ({
      handlePrevious,
      handleNext,
    }));

    // animation variants
    const slideVariants = {
      hiddenBefore: {
        rotate: 12,
        x: 0,
        y: 0,
      },
      visible: {
        rotate: 0,
        x: 0,
        y: 0,
      },
      hiddenAfter: {
        rotate: 0,
        x: "-100%",
        y: 0,
      },
    };

    return (
      <div className="progress-slider">
        {controls && (
          <div className="controls">
            <div onClick={handlePrevious}>→</div>
            <div onClick={handleNext}>←</div>
          </div>
        )}
        <motion.div
          className="progress-slides-wrapper"
          onPan={handlePan}
          onPanEnd={() => setPanThresholdCrossed(false)}
          style={{ touchAction: "pan-y" }}
        >
          <AnimatePresence>
            {children.map((child, i) => (
              <motion.div
                className={classNames({
                  "progress-slide": true,
                  active: currentSlide >= i,
                })}
                style={
                  {
                    zIndex: 2 + (() => {
                      if (direction === "right")
                        return Math.abs(i - currentSlide) * -1;
                      if (direction === "left")
                        return Math.abs(i + 1 - currentSlide) * -1;
                    })(),
                  } /* current slide should be always on top */
                }
                key={i}
                variants={slideVariants}
                initial={
                  i === currentSlide
                    ? "visible"
                    : direction === "left"
                    ? "hiddenBefore"
                    : "hiddenAfter"
                }
                ref={(ref) => (slidesRef.current[i] = ref)}
                animate={
                  (currentSlide === i && "visible") ||
                  (currentSlide === i + 1 && "hiddenAfter") ||
                  (currentSlide === i - 1 && "hiddenBefore")
                }
                exit="hiddenAfter"
              >
                {child}
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
      </div>
    );
  }
);

ProgressSlider.propTypes = {
  children: PropTypes.array,
  onChange: PropTypes.func,
  settings: PropTypes.shape({
    controls: PropTypes.bool,
  }),
};

ProgressSlider.displayName = "ProgressSlider";

export default ProgressSlider;
