import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import SimpleImage from "../../../image/simple-image";
import { self } from "../../../../config";

const ParagraphAudio = ({ content }) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(60);
  const [volume, setVolume] = useState(1);
  const [prevVolume, setPrevVolume] = useState(1);
  const player = useRef();

  const audioData = content.fieldAudioDatei.entity;
  const audioImage = audioData?.fieldImage?.entity;
  const audioFile = audioData?.fieldMediaAudioFile.entity.url;
  const theme = content.fieldTheme || "theme-1";

  const timeUpdateHandler = (e) => {
    setCurrentTime(e.target.currentTime);
    setDuration(e.target.duration);
  };

  const getTime = (time) => {
    if (!isNaN(time)) {
      return (
        Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
      );
    }

    return null;
  };

  const play = () => player.current.play();
  const pause = () => player.current.pause();

  const toggleMute = () => {
    if (player.current.volume === 0) {
      player.current.volume = prevVolume;
      setVolume(prevVolume);
    } else {
      setPrevVolume(volume);
      player.current.volume = 0;
      setVolume(0);
    }
  };

  const changeVolumeHandler = (e) => {
    setVolume(e.target.value);
    player.current.volume = e.target.value;
  };

  const sectionClassNames = classNames({
    "paragraph paragraph-audio": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-10 offset-lg-1">
            <div className={`audio-wrapper ${theme}`}>
              {audioImage && (
                <SimpleImage
                  src={audioImage.fieldMediaImage.style.url}
                  alt={audioImage.fieldMediaImage.alt || ""}
                  title={content.fieldTitleAudio}

                  wrapperClassName="image-section"
                />
              )}
              <div className="audio-section">
                <div className="text-wrapper">
                  {audioData.fieldTextKopf && (
                    <h3>{audioData.fieldTextKopf}</h3>
                  )}
                  {audioData.fieldTextAudio && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: audioData.fieldTextAudio.processed,
                      }}
                    />
                  )}
                </div>
                <div className="audio-controls-wrapper">
                  <audio
                    src={audioFile}
                    aria-controls="audio-controls"
                    ref={player}
                    onTimeUpdate={timeUpdateHandler}
                    onLoadedMetadata={timeUpdateHandler}
                  />
                  <>
                    {player.current?.paused ? (
                      <a role="button" onClick={play}>
                        <img
                          className="icon-audio-controls"
                          src={self + "/icon-play.svg"}
                          alt="Play Audio"
                        />
                      </a>
                    ) : (
                      <a role="button" onClick={pause}>
                        <img
                          className="icon-audio-controls"
                          src={self + "/icon-pause.svg"}
                          alt="Pause Audio"
                        />
                      </a>
                    )}
                  </>
                  <div className="time">
                    <div className="current">{getTime(currentTime)}</div>
                    <div className="progress-bar">
                      <div className="bar">
                        <div
                          className="fill"
                          style={{
                            width: `${(100 / duration) * currentTime}%`,
                          }}
                        />
                      </div>
                    </div>
                    <div className="duration">{getTime(duration)}</div>
                  </div>
                </div>

                <div className="volume-control">
                  <a className="volume-control-button" onClick={toggleMute}>
                    <img
                      className="icon-audio-controls"
                      src={self + "/icon-speaker.svg"}
                      alt="Volume"
                    />
                  </a>
                  <div className="volume-input-wrapper">
                    <input
                      type="range"
                      name="volume"
                      className="volume-input"
                      onChange={changeVolumeHandler}
                      value={volume}
                      step={0.2}
                      min={0}
                      max={1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphAudio.propTypes = {
  content: PropTypes.shape({
    fieldTheme: PropTypes.string,
    fieldTitleAudio: PropTypes.string,
    fieldAudioDatei: PropTypes.shape({
      entity: PropTypes.shape({
        fieldTextAudio: PropTypes.shape({
          processed: PropTypes.string,
        }),
        fieldTextKopf: PropTypes.string,
        fieldMediaAudioFile: PropTypes.shape({
          entity: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
        fieldBildAudio: PropTypes.shape({
          style: PropTypes.object.isRequired,
          alt: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        }),
      }),
    }),
  }),
};

export default ParagraphAudio;
