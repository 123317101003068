import classNames from "classnames";
import React from "react";

const Icon = (props) => {
  const svgClasses = classNames({
    opened: props.open,
    closed: !props.open,
    "accordion-icon": true,
  });
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      className={svgClasses}
    >
      <rect
        y="13"
        width="31"
        height="5"
        rx="1.5"
        fill="black"
        className="horizontal-line"
      />
      <rect
        y="13"
        width="31"
        height="5"
        rx="1.5"
        fill="black"
        className="vertical-line"
      />
    </svg>
  );
};

export default Icon;
