import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

const ParagraphBanner = (props) => {
  const elementCount = 10;
  const itemsRef = useRef([]);
  const [itemsWidth, setItemsWidth] = useState(0);

  useEffect(() => {
    setItemsWidth(itemsRef.current[0]?.clientWidth);
  }, []);

  return (
    <section className="paragraph paragraph-banner">
      <div className="banner-outer">
        <div
          className={`banner-wrapper ${
            props.content.fieldTheme || "theme-1-light"
          }`}
          style={{ "--banner-item-width": itemsWidth }}
        >
          {(() => {
            const elements = [];
            for (let i = 0; i < elementCount; i++) {
              elements.push(
                <span key={i} className="banner-content-item" ref={(ref) => itemsRef.current[i] = ref}>
                  {props.content.fieldContent}
                </span>
              );
            }
            return elements;
          })()}
        </div>
      </div>
    </section>
  );
};

ParagraphBanner.propTypes = {
  content: PropTypes.shape({
    fieldContent: PropTypes.string.isRequired,
    fieldTheme: PropTypes.oneOf([
      "theme-1",
      "theme-1-light",
      "theme-2",
      "theme-2-light",
    ]),
  }),
};

export default ParagraphBanner;
