import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { encodeUrl } from "../../../../lib/encode-url";

class ParagraphSectionheading extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-sectionheading": true,
    });

    const headlineSize = this.props.content.fieldType || "headline-l";
    const cta = this.props.content.fieldCallToAction;

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12">
              <h2
                id={encodeUrl(this.props.content.fieldHeading)}
                className={headlineSize}
              >
                <span className={cta ? "with-cta" : ""} dangerouslySetInnerHTML={{ __html: this.props.content.fieldHeading}}/>
                {cta?.url.routed === true && (
                  <span className="cta-wrapper">
                    <Link to={cta.url.path}>{cta.title}</Link>
                  </span>
                )}
                {cta?.url.routed === false && (
                  <span className="cta-wrapper">
                    <a href={cta.url.path} target="_blank" rel="noreferrer">
                      {cta.title}
                    </a>
                  </span>
                )}
              </h2>
              <div></div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphSectionheading.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldSetAnchorLink: PropTypes.bool,
  }),
};

export default ParagraphSectionheading;
