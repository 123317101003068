/**
 * Helper: Creates a random integer number between a given min and max value
 * @param {Number} min The min value that can be possibly returned
 * @param {Number} max The max value that can be possibly returned
 * @returns
 */
const createRandomInt = (min = 0, max = 10) =>
  Math.floor(Math.random() * (max - min) + min);

/**
 * Helper: Creates an array explicitly to be used inside the animate property
 * @param {Number} count The amount of items in the array
 * @param {Number} min The min value an item can be set to
 * @param {Number} max The max value an item can be set to
 * @param {Number} offset The overall offset for the values
 * @returns
 */
const createRandomArray = (count = 5, min = -20, max = 20, offset = 0) => {
  const arr = [];
  for (let i = 0; i < count; i++) {
    arr.push(createRandomInt(min, max) + offset);
  }
  // return the array and the first value at the and as well to avoid jumping from last value to first
  return [...arr, arr[0]];
};

/**
 * Helper: Generates evenly distributed amount of grid points from given width and height
 * @param {Number} amount Amount of grid cells needed
 * @param {Number} width The width of the given area
 * @param {Number} height The height of the given area
 * @returns
 */
const getGridPoints = (amount, width, height) => {
  // calculate square-pixels per tile
  const sqPixelsPerTile = (width * height) / amount;

  // split provided area into equal tiles
  let x = 1;
  let y = 1;

  // if the current tile-area is equal to or smaller than needed, break the loop
  while ((width / x) * (height / y) > sqPixelsPerTile) {
    if (width / x / (height / y) > 2) {
      x++;
    } else {
      y++;
    }
  }

  // generate gridpoints array that stores grid points like
  const gridPoints = [];
  const stepsX = 1 / x;
  const stepsY = 1 / y;
  for (let i = 0; i < y; i++) {
    for (let j = 0; j < x; j++) {
      gridPoints.push([stepsX * (j + 1), stepsY * (i + 1)]);
    }
  }

  // center grid points by shifting them by -50% of the stepsize
  const gridPointsCentered = gridPoints.map((point) => [
    point[0] - stepsX + 0.1,
    point[1] - stepsY / 2,
  ]);

  return gridPointsCentered;
};

export { getGridPoints, createRandomArray, createRandomInt };
