import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * Simple image component which renders the caption and credit as an overlay.
 */
const SimpleImage = ({ src, alt, credit, wrapperClassName, ...props }) => {
  return (
    <div
      className={classNames({
        "simple-image-wrapper": true,
        [wrapperClassName]: !!wrapperClassName,
      })}
    >
      <img src={src} alt={alt} {...props} />
      {credit && (
        <div className="simple-image-overlay">
          <span className="copyright-symbol">©</span>
          <span className="copyright-content">{credit}</span>
        </div>
      )}
    </div>
  );
};

SimpleImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  caption: PropTypes.string,
  credit: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default SimpleImage;
