import React from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import EditButton from "../../../backend/edit-button";
import DateTime from "./components/datetime";
import { Link } from "react-router-dom";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

/**
 * @todo use "FieldTime"
 */
const TeaserEvent = (props) => {
  return (
    <article
      className={`node node-${props.item.entityId} node-teaser node-teaser-default teaser-veranstaltung col-12`}
    >
      <EditButton
        adminApp={props.adminApp}
        entityId={props.item.entityId}
        destinationRoute={props.location.pathname}
      />
      <div className="row">
        <div className="col-12 col-lg-4 event-meta d-flex">
          <DateTime data={props.item.fieldDatum} detailed={false} />
          <p className="event-type">
            {props.item.fieldSchlagwort[0]?.entity.name}
          </p>
        </div>
        <div className="col-12 col-lg-8">
          <Link
            to={props.item.entityUrl.path}
            className="event-main card transparent hover"
          >
            {props.item.fieldBild !== null && (
              <div className="image-wrapper">
                <Image
                  data={props.item.fieldBild.entity.fieldMediaImage}
                  nodeTitle={props.item.title}
                />
              </div>
            )}
            <div className="text-wrapper">
              <h3 className="headline-l">{props.item.title}</h3>
            </div>
          </Link>
        </div>
      </div>
    </article>
  );
};

export const teaserEventPropTypes = PropTypes.shape({
  entityId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  entityUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldOrt: PropTypes.string,
  fieldDauerAusblenden: PropTypes.bool,
  fieldText: PropTypes.shape({
    processed: PropTypes.string.isRequired,
  }),
  fieldBild: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
  fieldDatum: PropTypes.shape({
    value: PropTypes.string,
    endValue: PropTypes.string,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldTicketlink: PropTypes.shape({
    url: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
});

TeaserEvent.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserEventPropTypes,
  location: PropTypes.object.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default connect(mapStateToProps)(withRouter(injectIntl(TeaserEvent)));
