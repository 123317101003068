import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import SimpleImage from "../../../../image/simple-image";

const IntroVariantB = ({ content }) => (
  <section className="paragraph paragraph-simple-intro variant-b">
    <div className="content-wrapper">
      <div className="image-wrapper">
        <SimpleImage
          src={content.imageData.full}
          alt={content.imageData.alt}
          credit={content.imageData.credit}
        />
        {/* <div className="caption-wrap">
          {content.imageData.caption}
        </div> */}
      </div>
      <div className="container">
        <div className={`card text-wrapper ${content.theme || "theme-1-light"}`}>
          {!!content.tags.length && (
            <div className="tags-wrapper">
              {content.tags.map((tag, i) => (
                <div className="tag" key={i}>
                  {tag.entity.entityLabel}
                </div>
              ))}
            </div>
          )}
          <h1 className={content.title.classNames}>{content.title.content}</h1>
          <p>{content.abstract}</p>
        </div>
      </div>
    </div>
  </section>
);

IntroVariantB.propTypes = {
  content: PropTypes.object.isRequired,
};

export default IntroVariantB;
