import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const Error500 = ({ error }) => {
  console.error(error);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-4 offset-md-4">
          <h1 className="text-center">500</h1>
          <p className="text-center">
            <FormattedMessage id="500" />
            <pre className="text-center">{error.message}</pre>
          </p>
        </div>
      </div>
    </div>
  );
};

Error500.propTypes = {
  error: PropTypes.object.isRequired,
};

export default Error500;
