import React, { useEffect, useRef, useState } from "react";
import { encodeUrl } from "../../lib/encode-url";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { Link } from "react-scroll";
import useOutsideClickHandler from "../../hooks/use-outside-click-handler";
import PropTypes from "prop-types";

const InPageNavigation = ({ modules }) => {
  const navRef = useRef();
  const [open, setOpen] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [y, setY] = useState(0);

  const links = modules
    .filter((item) => {
      if (!item.entity?.fieldSetAnchorLink) return false;
      return true;
    })
    .map((item) => {
      return item.entity.fieldHeading || item.entity.fieldTitle;
    });
    
  if (!links.length) return null;

  const navHeight = navRef.current?.scrollHeight;

  const formattedLinks = links.map((link) => ({
    label: link,
    link: encodeUrl(link),
  }));

  const toggleOpenHandler = () => {
    setOpen((prev) => {
      if (prev) {
        // if it was opened, close it
        navRef.current.style.maxHeight = "0px";
      } else {
        // if it was closed, open it
        navRef.current.style.maxHeight = navHeight + "px";
      }
      return !prev;
    });
  };

  const sectionRef = useOutsideClickHandler(toggleOpenHandler, open);

  const scrollHandler = () => {
    const scrolledY = window.scrollY;

    setY((prevY) => {
      if (scrolledY < 300) {
        setHidden(false);
      } else {
        if (prevY < scrolledY) {
          setHidden(true);
          navRef.current.style.maxHeight = "0px";
          setOpen(false);
        }
        if (prevY > scrolledY) setHidden(false);
      }
      return scrolledY;
    });
  };

  useEffect(() => {
    document && document.addEventListener("scroll", scrollHandler);

    // cleanup
    return () => {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <section
      className={classNames({
        "in-page-navigation": true,
        hidden: hidden,
      })}
      ref={sectionRef}
    >
      <div
        className={classNames({
          label: true,
          open: open,
        })}
        role="button"
        onClick={toggleOpenHandler}
      >
        <FormattedMessage id="inpage.on_this_page" />
      </div>
      <nav ref={navRef} style={{ maxHeight: "0px" }}>
        <ul>
          {formattedLinks.map((link, i) => (
            <li key={i}>
              <Link
                to={link.link}
                onClick={toggleOpenHandler}
                smooth={true}
                offset={-50}
                duration={500}
              >
                {link.label}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </section>
  );
};

InPageNavigation.propTypes = {
  modules: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        fieldHeading: PropTypes.string,
      }),
    })
  ),
};

export default InPageNavigation;
