import React from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";

import LoadingIndicator from "../general-components/loading-indicator";
import Social from "./components/social";
import Menu from "./components/menu";
import WuebbenLogo from "../header/components/wuebben-logo";
import { FormattedMessage } from "react-intl";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  language: reduxStore.i18n.currentLanguage,
});

/**
 * @todo Add title attribute to links.
 */
const PageFooter = (props) => {
  const currentLanguage = useSelector((state) => state.i18n.currentLanguage);
  const footerConfig = props.footerConfig?.entities?.filter((config) => {
    return config.context.includes(currentLanguage);
  })[0];

  console.log(props.footerConfig);

  if (footerConfig) {
    return (
      <section className="footer-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-3">
              <div className="logo-wrapper">
                <WuebbenLogo />
              </div>
            </div>
            <div className="col-12 col-md-4 col-xl-3">
              <div className="contact-wrapper">
                <p className="label">
                  <strong>
                    <FormattedMessage id="footer.contact_us" />
                  </strong>
                </p>
                {footerConfig.fieldPhone && (
                  <p>T {footerConfig.fieldPhone}</p>
                )}
                {footerConfig.fieldEmail && (
                  <p>{footerConfig.fieldEmail}</p>
                )}
                {footerConfig.fieldStreet && (
                  <p>{footerConfig.fieldStreet}</p>
                )}
                {footerConfig.fieldZip &&
                  footerConfig.fieldCity &&
                  footerConfig.fieldCountry && (
                    <p>
                      {footerConfig.fieldZip}{" "}
                      {footerConfig.fieldCity},{" "}
                      {footerConfig.fieldCountry}
                    </p>
                  )}
              </div>
            </div>
            {!!props.menuSitemap?.links.length && (
              <div className="col-12 col-md-4 col-xl-3">
                <div className="menu-sitemap-wrapper">
                  <p className="label">
                    <strong>
                      <FormattedMessage id="footer.on_this_page" />
                    </strong>
                  </p>
                  <Menu items={props.menuSitemap.links} />
                </div>
              </div>
            )}
            <div className="col-12 col-md-4 col-xl-3">
              <p className="label">
                <strong>
                  <FormattedMessage id="footer.follow_us" />
                </strong>
              </p>
              <Social footerConfig={footerConfig} lang={props.language} />
            </div>
          </div>
          {props.menuLegal?.links.length && (
            <div className="row footer-legal">
              <div className="col-12 col-xl-9 offset-xl-3">
                <div className="menu-legal-wrapper">
                  <Menu items={props.menuLegal.links} />
                  <p>{footerConfig.fieldCopyright}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }

  return <LoadingIndicator />;
};

PageFooter.propTypes = {
  adminApp: PropTypes.bool,
  footerConfig: PropTypes.shape({
    fieldPhone: PropTypes.string,
    fieldEmail: PropTypes.string,
    fieldStreet: PropTypes.string,
    fieldZip: PropTypes.string,
    fieldCity: PropTypes.string,
    fieldCountry: PropTypes.string,
    fieldFooterSitemapZeigen: PropTypes.bool,
    fieldCopyright: PropTypes.string.isRequired,
    fieldMenue: PropTypes.shape({
      targetId: PropTypes.string,
    }),
    fieldFacebook: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldYoutube: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldFlickr: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldInstagram: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldPinterest: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldLinkedin: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldTwitter: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldVimeo: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldXing: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldTumblr: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
  menuSitemap: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.shape({
          path: PropTypes.string,
        }),
      })
    ),
  }),
  menuLegal: PropTypes.shape({
    links: PropTypes.array,
  }),
};

export default connect(mapStateToProps)(PageFooter);
