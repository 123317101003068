import React, { useRef, useState } from "react";
import ProgressStep from "./components/progress-step";
import PropTypes from "prop-types";
import ProgressSlider from "./components/progress-slider";
import { encodeUrl } from "../../../../lib/encode-url";

const ParagraphProgress = ({ content }) => {
  const progressSliderRef = useRef();
  const [currentSlide, setCurrentSlide] = useState();

  const slidesCount = content.fieldDeckTitle
    ? content.fieldSteps?.length + 1
    : content.fieldSteps?.length;

  // don't show module if no steps are provided
  if (!content.fieldSteps?.length) return;

  return (
    <section className="paragraph paragraph-progress">
      <div className="container">
        <div className="title-wrapper">
          <h2 id={encodeUrl(content.fieldTitle)}>{content.fieldTitle}</h2>
          <div className="slider-controls">
            <button
              onClick={progressSliderRef.current?.handlePrevious}
              disabled={currentSlide === 0}
            >
              ←
            </button>
            <button
              onClick={progressSliderRef.current?.handleNext}
              disabled={currentSlide === slidesCount - 1}
            >
              →
            </button>
          </div>
        </div>
        <div className="row">
          <ProgressSlider
            ref={progressSliderRef}
            settings={{ controls: false }}
            onChange={(value) => setCurrentSlide(value)}
          >
            {content.fieldDeckTitle && (
              <div className="card deck theme-2">
                <h3 className="headline-l">{content.fieldDeckTitle}</h3>
                {content.fieldDeckText && (
                  <div className="body">
                    <p>{content.fieldDeckText}</p>
                  </div>
                )}
              </div>
            )}
            {content.fieldSteps.map((step, i) => (
              <ProgressStep
                key={i}
                step={step}
                index={i}
                length={content.fieldSteps?.length}
              />
            ))}
          </ProgressSlider>
        </div>
      </div>
    </section>
  );
};

ParagraphProgress.propTypes = {
  content: PropTypes.shape({
    fieldTitle: PropTypes.string.isRequired,
    fieldSteps: PropTypes.arrayOf(PropTypes.object),
    fieldDeckTitle: PropTypes.string,
    fieldDeckText: PropTypes.string,
  }),
};

export default ParagraphProgress;
