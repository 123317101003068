import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import ErrorBoundary from "../../../../error-boundary";
import Icon from "./components/icon";
import FieldTime from "../../../date-time/field-time";

const ParagraphAkkordionText = (props) => {
  const [openedItems, setOpenedItems] = useState([]);

  const sectionClassNames = classNames({
    "paragraph paragraph-akkordion-text": true,
  });

  const accordionSettings = {
    allowZeroExpanded: true,
    allowMultipleExpanded: true,
  };

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Accordion
              {...accordionSettings}
              onChange={(uuids) => setOpenedItems(uuids)}
            >
              {props.content.fieldAbschnitte.map((item, index) => (
                <ErrorBoundary key={index}>
                  <AccordionItem
                    uuid={index}
                    className={classNames({
                      "card accordion-item": true,
                      open: !!openedItems.includes(index),
                    })}
                  >
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <div className="accordion-header-wrapper">
                          <div className="icon-wrapper">
                            <Icon open={!!openedItems.includes(index)} />
                          </div>
                          <div>
                            {item.entity.fieldDate && (
                              <div className="date headline-s">
                                <FieldTime
                                  date={item.entity.fieldDate.value}
                                  format={"DD. MMMM YYYY"}
                                />
                              </div>
                            )}
                            <h3 className="headline-l">
                              {item.entity.fieldTitel}
                            </h3>
                          </div>
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordion-body-wrapper">
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{
                            __html: item.entity.fieldText.processed,
                          }}
                        />
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </ErrorBoundary>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphAkkordionText.propTypes = {
  content: PropTypes.shape({
    fieldAbschnitte: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldTitel: PropTypes.string,
          fieldText: PropTypes.shape({
            processed: PropTypes.string,
          }),
        }),
      })
    ),
  }),
};

export default ParagraphAkkordionText;
