import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import LoadingIndicator from "../general-components/loading-indicator";
import ContentBase from "../general-components/content-base/content-base";
import landingPageQuery from "./landingpage.graphql";
import PagerFullPage from "../general-components/pager-full-page";
import { i18nActionHelper } from "./../i18n/i18n";
import InPageNavigation from "../general-components/in-page-navigation/in-page-navigation";
import PromotionProgramIntro from "../promotion-program/components/intro";
import FellowIntro from "../fellow/components/fellow-intro";
import Error404 from "../errors/404";
import ParagraphSEO from "../general-components/content-base/paragraphs/seo/paragraph-seo";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  pagerFullPageContextId: reduxStore.appStore.pagerFullPageContextId,
  pagerConfig: reduxStore.appStore.pagerFullPages,
  initialLoad: reduxStore.appStore.initialLoad,
});

class Landingpage extends Component {
  componentDidMount() {
    i18nActionHelper(this.props, this.props.dispatch);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.data.route) !==
      JSON.stringify(prevProps.data.route)
    ) {
      i18nActionHelper(this.props, this.props.dispatch);
    }
  }

  render() {
    /* if (this.props.initialLoad) {
      return <LoadingIndicator />;
    } */
    if (this.props.data.route && !this.props.data.loading) {
      const reducedContent = this.props.data.route.entity;
      const published = reducedContent.status
        ? "node-published"
        : "node-unpublished";

      return (
        <article
          className={`node-${reducedContent.entityBundle} node-${reducedContent.entityId} node-full-page ${published}`}
        >
          <Helmet>
            <title>WSW | {reducedContent.entityLabel}</title>
          </Helmet>

          {reducedContent.fieldSeo && (
            <ParagraphSEO content={reducedContent.fieldSeo?.entity} />
          )}

          <InPageNavigation modules={reducedContent.fieldModules} />

          {reducedContent.entityBundle === "promotion_program" && (
            <PromotionProgramIntro
              title={reducedContent.title}
              teaserText={reducedContent.fieldTeasertext}
              facts={reducedContent.fieldFacts}
            />
          )}

          {reducedContent.entityBundle === "fellow" && (
            <section className="intro-wrap">
              <FellowIntro
                title={reducedContent.title}
                teaserText={reducedContent.fieldTeasertext}
                teaserImage={{
                  ...reducedContent.fieldTeaserbild?.entity.fieldMediaImage,
                  credit: reducedContent.fieldTeaserbild?.entity.fieldCredit,
                  caption: reducedContent.fieldTeaserbild?.entity.fieldCaption,
                }}
                theme={reducedContent.fieldTheme}
                tags={reducedContent.fieldSchlagwort}
                institution={reducedContent.fieldInstitution.entity.name}
                subject={reducedContent.fieldSubject.entity.name}
              />
            </section>
          )}

          {reducedContent.fieldIntro && (
            <ContentBase
              content={[reducedContent.fieldIntro]}
              className="intro-wrap"
            />
          )}

          {reducedContent.fieldModules && (
            <ContentBase content={reducedContent.fieldModules} />
          )}

          {this.props.pagerFullPageContextId &&
            this.props.pagerConfig.filter(
              (config) => config.id === this.props.pagerFullPageContextId
            ).length > 0 && (
              <PagerFullPage
                config={
                  this.props.pagerConfig.filter(
                    (config) => config.id === this.props.pagerFullPageContextId
                  )[0]
                }
                currentId={reducedContent.entityId}
              />
            )}
        </article>
      );
    }

    if (this.props.data.route === null) {
      return <Error404 />;
    }

    return <LoadingIndicator />;
  }
}

Landingpage.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.array,
  pagerFullPageContextId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
  initialLoad: PropTypes.bool,
};

export default graphql(landingPageQuery, {
  options: (props) => ({
    variables: { path: props.location.pathname },
  }),
})(connect(mapStateToProps)(withRouter(Landingpage)));
