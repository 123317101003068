import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { quotePropTypes } from "../quote/paragraph-quote";
import Slider from "react-slick";

const ParagraphQuoteSlider = ({ content }) => {
  const sliderRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);

  const quotes = content.fieldQuotes
    .filter(
      (quote) => !!quote.entity.entityPublished && !!quote.entity.fieldQuote
    )
    .map((quote) => quote.entity);

  const themeMapping = {
    "theme-1": "theme-1",
    "theme-1-light": "theme-1",
    "theme-2": "theme-2",
    "theme-2-light": "theme-2",
    "theme-grey": "theme-grey",
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentSlide,
    speed: 500,
    arrows: false,
    adaptiveHeight: false,
    afterChange: (index) => setCurrentSlide(index),
  };

  const handlePrevious = () => {
    // don't do anything if first slide is reached.
    if (currentSlide <= 0) return;
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    // don't do anything if last slide is reached.
    if (currentSlide >= quotes.length - 1) return;
    sliderRef.current.slickNext();
  };

  return (
    <section className="paragraph paragraph-quote-slider">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-10 offset-lg-1 col-xl-8">
            <Slider {...sliderSettings} ref={sliderRef}>
              {quotes.map((quote, i) => (
                <div className="quote-wrapper" key={i}>
                  <blockquote
                    className="quote"
                    dangerouslySetInnerHTML={{ __html: quote.fieldQuote }}
                  />
                  {quote.fieldAuthor && (
                    <p className="author">{quote.fieldAuthor}</p>
                  )}
                </div>
              ))}
            </Slider>
            <div className="slider-controls">
              <button
                className={`button button-previous ${
                  content.fieldTheme
                    ? themeMapping[content.fieldTheme]
                    : "theme-1"
                }`}
                onClick={handlePrevious}
                disabled={currentSlide <= 0}
              >
                ←
              </button>
              <div className="progress">
                {currentSlide + 1}&thinsp;/&thinsp;{quotes.length}
              </div>
              <button
                className={`button button-next ${
                  content.fieldTheme
                    ? themeMapping[content.fieldTheme]
                    : "theme-1"
                }`}
                onClick={handleNext}
                disabled={currentSlide >= quotes.length - 1}
              >
                →
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphQuoteSlider.propTypes = {
  content: PropTypes.shape({
    fieldTheme: PropTypes.string,
    fieldQuotes: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape(quotePropTypes),
      })
    ),
  }),
};

export default ParagraphQuoteSlider;
