import React from "react";
import PropTypes from "prop-types";

const PromotionProgramIntro = (props) => {
  return (
    <section className="promotion-program-intro">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="inner-wrapper">
              <h1 dangerouslySetInnerHTML={{ __html: props.title }} />
              {props.teaserText && (
                <div
                  className="text big"
                  dangerouslySetInnerHTML={{
                    __html: props.teaserText.processed,
                  }}
                />
              )}
              {!!props.facts.length && (
                <div className="facts">
                  {props.facts.map((fact, i) => (
                    <div className="flex-wrapper" key={i}>
                      <div className="fact-key">
                        <strong>{fact.entity.fieldTitle}</strong>
                      </div>
                      <div className="fact-value">{fact.entity.fieldValue}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

PromotionProgramIntro.propTypes = {
  title: PropTypes.string,
  teaserText: PropTypes.shape({
    processed: PropTypes.string,
  }),
  facts: PropTypes.array,
}

export default PromotionProgramIntro;
