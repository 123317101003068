import React from "react";
import PropTypes from "prop-types";
import MediaContent from "./media-content";
import { AnimatePresence, motion } from "framer-motion";
import { FormattedMessage } from "react-intl";
import { self } from "../../config";

const MediaOverlay = ({ entityUrl, onClose }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 100 }}
      transition={{ duration: 0.3 }}
      className="overlay overlay-media"
    >
      <AnimatePresence>
        <MediaContent entityUrl={entityUrl} />
      </AnimatePresence>
      <div className="close-button">
        <button onClick={onClose}>
          <FormattedMessage id="close" />
          <img src={self + "/icon-close.svg"} />
        </button>
      </div>
    </motion.div>
  );
};

MediaOverlay.propTypes = {
  entityUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export default MediaOverlay;
