import React, { Component, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TeaserListWrap from "./components/component-teaserlist-wrap";
import TeaserEventList from "./events/component-teaser-event-list";
import ComponentTeaserlistCarousel from "./components/component-teaserlist-carousel";
import Link from "../../../link/link";
import ErrorBoundary from "../../../../error-boundary";
import { teaserNewsPropTypes } from "../../../teaser-base/news/teaser-news";
import { teaserEventPropTypes } from "../../../teaser-base/event/teaser-event";
import { teaserPersonPropTypes } from "../../../teaser-base/person/teaser-person";
import { teaserGeneralPropTypes } from "../../../teaser-base/general/teaser-general";
import { teaserProjectPropTypes } from "../../../teaser-base/project/teaser-projekt";
import { encodeUrl } from "../../../../lib/encode-url";
import ParagraphTeaserListFellows from "./fellows/paragraph-teaser-list-fellows";

/**
 * This is the component for the "Eierlegende Wollmilchsau" paragraph.
 * Try not not to use this! This is just for reference.
 *
 * Usage: Copy to
 * src/js/general-components/content-base/paragraphs/teaserliste/<content_type>/paragraphs-teaser-list<content_type>.jsx
 * and change/remove/add as needed.
 */
const ParagraphTeaserList = (props) => {
  const sliderRef = useRef();
  const prevSlideButton = useRef();
  const nextSlideButton = useRef();
  const [slideId, setSlideId] = useState(0);
  const [nodeCount, setNodeCount] = useState(0);
  const [slideIndex, setSlideIndex] = useState(0);

  const sectionClassNames = classNames({
      "paragraph paragraph-teaser-list": true,
      [`paragraph-teaser-list-${props.content.fieldAnzahlDerAnzuzeigendenI}`]: true,
      "paragraph paragraph-teaser-list-slick": props.content.fieldKarussel,
      [`paragraph-teaser-list-${props.content.fieldTyp}`]: true,
    }),
    eventOnly = props.content.fieldTyp === "veranstaltung",
    fellowOnly = props.content.fieldTyp === "fellow";

  let manualNodes = props.content.fieldBeitraegeAll;

  switch (props.content.fieldTyp) {
    case "news": {
      manualNodes = props.content.fieldBeitraegeNews;
      break;
    }
    case "person": {
      manualNodes = props.content.fieldBeitraegePerson;
      break;
    }
    default: {
      manualNodes = props.content.fieldBeitraegeAll;
    }
  }

  const getNodeQueryLength = (ammount) => {
    // Set length of automatic nodes
    if (
      props.content.fieldAutmatischOderManuell === "Automatisch (chronologisch)"
    ) {
      setNodeCount(ammount);
    } else {
      // Set length of manual nodes
      setNodeCount(manualNodes.length);
    }
  };

  // Get current slide from slick carousel
  const getCurrentSlideId = (currentSlideId) => {
    setSlideId(currentSlideId);
  };

  const carouselChangeHandler = (i, slidesToShow, totalCount) => {
    setSlideIndex((i + slidesToShow) / totalCount);
  };

  const prevSlide = () => {
    if (setSlideId !== 0) {
      sliderRef.current.slickPrev();
    }
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  return (
    <section className={sectionClassNames}>
      {props.content.fieldHeading && (
        <div className="content-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="header-wrapper">
                  <h2
                    className="headline-l"
                    id={encodeUrl(props.content.fieldHeading)}
                  >
                    {props.content.fieldHeading}
                  </h2>
                  <div className="arrow-portal"/>
                  {props.content.fieldKarussel && !(slideId === 0 && slideIndex >= 1) && (
                    <div className="slider-controls">
                      <button
                        ref={prevSlideButton}
                        onClick={prevSlide}
                        data-disabled={slideId === 0}
                      >
                        ←
                      </button>
                      <button
                        ref={nextSlideButton}
                        onClick={nextSlide}
                        data-disabled={slideIndex >= 1}
                      >
                        →
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {fellowOnly && (
        <ParagraphTeaserListFellows
          count={
            props.content.fieldAnzahlDerAnzuzeigendenI
              ? props.content.fieldAnzahlDerAnzuzeigendenI
              : 100
          }
          manualNodes={props.content.fieldManualFellows}
          nodesConfig={props.content.fieldAutmatischOderManuell}
          type={"fellow"}
          tags={props.content.fieldSchlagwort}
        />
      )}

      {eventOnly && (
        <div className="container">
          <div className="row">
            <ErrorBoundary>
              <TeaserEventList
                count={
                  props.content.fieldAnzahlDerAnzuzeigendenI
                    ? props.content.fieldAnzahlDerAnzuzeigendenI
                    : 100
                }
                manualNodes={props.content.fieldBeitraegeVeranstaltung}
                nodesConfig={props.content.fieldAutmatischOderManuell}
                type={props.content.fieldTyp}
                tags={props.content.fieldSchlagwort}
              />
            </ErrorBoundary>
          </div>
        </div>
      )}

      {!eventOnly && !fellowOnly && props.content.fieldKarussel &&  (
        <div className="container">
          <div className="carousel-wrapper">
            <ErrorBoundary>
              <ComponentTeaserlistCarousel
                manualNodes={manualNodes}
                id={props.content.entityId}
                nodesConfig={props.content.fieldAutmatischOderManuell}
                count={
                  props.content.fieldAnzahlDerAnzuzeigendenI
                    ? props.content.fieldAnzahlDerAnzuzeigendenI
                    : 100
                }
                type={props.content.fieldTyp}
                pagerFullPage={
                  props.content.fieldPagerAufVollseiten
                    ? props.content.entityId
                    : false
                }
                pagerFullPageOverviewLink={props.content.fieldLinkZurUebersicht}
                tags={props.content.fieldSchlagwort}
                ref={sliderRef}
                getCurrentSlideId={getCurrentSlideId}
                getNodeQueryLength={getNodeQueryLength}
                onChange={carouselChangeHandler}
              />
            </ErrorBoundary>
          </div>
        </div>
      )}

      {!eventOnly && !fellowOnly && !props.content.fieldKarussel && (
        <div className="container">
          <div className="row">
            <ErrorBoundary>
              <TeaserListWrap
                manualNodes={manualNodes}
                id={props.content.entityId}
                nodesConfig={props.content.fieldAutmatischOderManuell}
                count={props.content.fieldAnzahlDerAnzuzeigendenI}
                type={props.content.fieldTyp}
                pagerFullPage={
                  props.content.fieldPagerAufVollseiten
                    ? props.content.entityId
                    : false
                }
                pagerFullPageOverviewLink={props.content.fieldLinkZurUebersicht}
                tags={props.content.fieldSchlagwort}
              />
            </ErrorBoundary>
          </div>
        </div>
      )}

      {props.content.fieldMehrMeldungenButtonZeig &&
        props.content.fieldMehrMeldungenButton.title && (
          <div className="container">
            <div className="row">
              <div className="col-16 d-md-flex justify-content-md-end">
                <ErrorBoundary>
                  <Link
                    link={props.content.fieldMehrMeldungenButton}
                    className="btn btn-primary more"
                  />
                </ErrorBoundary>
              </div>
            </div>
          </div>
        )}
    </section>
  );
};

ParagraphTeaserList.propTypes = {
  content: PropTypes.shape({
    entityId: PropTypes.string,
    fieldKarussel: PropTypes.bool,
    fieldAnzahlDerAnzuzeigendenI: PropTypes.number,
    fieldPagerAufVollseiten: PropTypes.bool,
    fieldMehrMeldungenButtonZeig: PropTypes.bool,
    fieldTyp: PropTypes.oneOf([
      "news",
      "person",
      "veranstaltung",
      "fellow",
      "all",
    ]),
    fieldHeading: PropTypes.string,
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        targetId: PropTypes.string,
      })
    ),
    fieldAutmatischOderManuell: PropTypes.oneOf([
      "Automatisch (chronologisch)",
      "Manuell",
    ]),
    fieldLinkZurUebersicht: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.bool,
      }),
    }),
    fieldMehrMeldungenButton: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.bool,
      }),
    }),
    fieldBeitraegeAll: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.oneOfType([
          teaserNewsPropTypes,
          teaserEventPropTypes,
          teaserPersonPropTypes,
          teaserGeneralPropTypes,
          teaserProjectPropTypes,
        ]),
      })
    ),
    fieldBeitraegeVeranstaltung: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserEventPropTypes,
      })
    ),
    fieldBeitraegeNews: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserNewsPropTypes,
      })
    ),
    fieldBeitraegePerson: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserPersonPropTypes,
      })
    ),
  }),
};

export default ParagraphTeaserList;
