import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SimpleImage from "../../../image/simple-image";

const CardWrapper = ({ children, link, theme }) => {
  const themeMapping = {
    "theme-1": "theme-1",
    "theme-1-light": "theme-1",
    "theme-2": "theme-2",
    "theme-2-light": "theme-2",
    "theme-grey": "theme-grey",
  };

  const themeClassNames =
    (theme && !!link && themeMapping[theme]) ||
    (!theme && !!link && "theme-1") ||
    (theme && !link && theme) ||
    (!theme && !link && "theme-1-light");

  const wrapperClassNames = `card hover ${themeClassNames}`;

  if (link)
    return (
      <Link to={link} className={wrapperClassNames}>
        {children}
      </Link>
    );

  return <div className={wrapperClassNames}>{children}</div>;
};

CardWrapper.propTypes = {
  children: PropTypes.element,
  link: PropTypes.string,
  theme: PropTypes.string,
};

const ParagraphContentTeaser = ({ content }) => {
  return (
    <section className="paragraph paragraph-event-spotlight">
      <article className="container node-teaser teaser-veranstaltung">
        <div className="row">
          <div className="col-12 col-md-4 event-meta">
            {content.fieldImage && (
              <div className="image-wrapper">
                <SimpleImage
                  src={content.fieldImage.entity.fieldMediaImage.style.url}
                  alt={content.fieldImage.entity.fieldMediaImage.alt}
                  credit={content.fieldImage.entity.fieldCredit}
                />
              </div>
            )}
          </div>
          <div className="col-12 col-md-8 text-and-title">
            <CardWrapper
              link={content.fieldReferencedContent?.entity.entityUrl.path}
              theme={content.fieldTheme}
            >
              {!!content.fieldSchlagwort.length && (
                <div className="type-wrapper">
                  <p className="event-type headline-s">
                    {content.fieldSchlagwort[0]?.entity.name}
                  </p>
                </div>
              )}
              {content.fieldTitle && (
                <div className="d-flex justify-content-between align-items-start">
                  <h3 className="headline-l">{content.fieldTitle}</h3>
                </div>
              )}
              {content.fieldText && (
                <div className="text" dangerouslySetInnerHTML={{ __html: content.fieldText.processed }}/>
              )}
            </CardWrapper>
          </div>
        </div>
      </article>
    </section>
  );
};

ParagraphContentTeaser.propTypes = {
  /**
   * The modules content.
   */
  content: PropTypes.shape({
    fieldReferencedContent: PropTypes.object,
    fieldTitle: PropTypes.string,
    fieldText: PropTypes.string,
    fieldTheme: PropTypes.string,
    fieldSchlagwort: PropTypes.array,
    fieldImage: PropTypes.object,
  }).isRequired,
};

export default ParagraphContentTeaser;
