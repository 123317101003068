import { useEffect, useState } from "react";

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1200,
  xxl: 1920,
};

const getScreen = (size) => window.innerWidth < breakpoints[size];

/**
 * React Hook that returns a boolean state wheather the current screen size is within this breakpoint or not.
 * Forces a rerender on every screen resize.
 * @param {"xs" | "sm" | "md" | "lg" | "xl" | "xxl"} size The breakpoint name.
 * @param {function} callback Callback function that triggers, when state changes.
 * @returns Boolean, wheather the current screen size is within the named breakpoint.
 */
const useScreenSize = (size, callback) => {
  const [isScreenSize, setIsScreenSize] = useState(false);

  useEffect(() => {
    const onResize = () => setIsScreenSize(getScreen(size));

    onResize();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(callback, [isScreenSize]);

  return isScreenSize;
};

export default useScreenSize;
