import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import Image from "../../../../image/image";
import SimpleImage from "../../../../image/simple-image";

const ClaimTeaserEvent = ({ content }) => {
  const date = content.fieldDatum.value;
  const formattedDate = {
    weekDay: moment.utc(date).local().format("dd"),
    day: moment.utc(date).local().format("DD"),
    month: moment.utc(date).local().format("MMMM").substring(0, 3),
    time: moment.utc(date).local().format("HH:mm"),
  };

  return (
    <div className="claim-teaser claim-teaser-event">
      <Link to={content.entityUrl.path}>
        {content.fieldBild && (
          <SimpleImage
            src={content.fieldBild.entity.fieldMediaImage.style.url}
            alt={content.fieldBild.entity.fieldMediaImage.alt}
            credit={content.fieldBild.entity.fieldCredit}
          />
        )}
        <div className="card grey">
          <div className="card-inner">
            <div className="date">
              {formattedDate.weekDay}
              <br />
              {formattedDate.day}. {formattedDate.month}
            </div>
            <div className="title">
              <h3>{content.title}</h3>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

ClaimTeaserEvent.propTypes = {
  content: PropTypes.shape({
    entityUrl: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    fieldDatum: PropTypes.object.isRequired,
    fieldBild: PropTypes.object,
  }),
};

export default ClaimTeaserEvent;
