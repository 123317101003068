import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import menuQuery from "../general-components/menu-query.graphql";
import { useQuery } from "@apollo/client";
import WuebbenSignet from "./components/wuebben-signet";

import { AnimatePresence, motion } from "framer-motion";
import WuebbenLogo from "./components/wuebben-logo";
import LanguageSwitch from "../i18n/language-switch";
import useScreenSize from "../hooks/use-screen-size";
import useOutsideClickHandler from "../hooks/use-outside-click-handler";
import { useSelector } from "react-redux";
import classNames from "classnames";

const SimpleNavigation = () => {
  const currentLanguage = useSelector((store) => store.i18n.currentLanguage);
  const { loading, data: mainMenuQuery } = useQuery(menuQuery, {
    variables: {
      name: "main",
      language: currentLanguage?.toUpperCase() || "DE",
    },
  });
  const [menuOpen, setMenuOpen] = useState(false);
  const [sneak, setSneak] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const isMobile = useScreenSize("lg", () => setIsLoaded(true));
  const mainNav = useRef();
  const squareRef = useOutsideClickHandler(() => setMenuOpen(false));
  const mainNavHeight = mainNav.current?.clientHeight + 100;

  const backgroundAnimation = {
    hidden: {
      width: isMobile ? 100 : 150,
      height: isMobile ? 120 : 180,
      rotate: -12,
      transition: {
        duration: 0.3,
        type: "spring",
        bounce: 0.2,
      },
    },
    sneak: {
      rotate: isMobile ? -12 : -10,
      width: isMobile ? 100 : 160,
      height: isMobile ? 120 : 190,
      transition: {
        duration: 0.3,
        type: "spring",
        bounce: 0.2,
      },
    },
    show: {
      width: isMobile ? "120%" : 400,
      height: mainNavHeight,
      rotate: 0,
      transition: {
        duration: 0.3,
        type: "spring",
        bounce: 0.2,
      },
    },
  };

  const toggleFullScreenMenu = () => {
    setMenuOpen((prev) => !prev);
    setSneak(false);
  };

  const animationContainer = {
    hidden: { x: 50, opacity: 0 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        delayChildren: 0.15,
        staggerChildren: 0.05,
      },
    },
  };

  const animationItem = {
    hidden: { x: 50, opacity: 0 },
    show: { x: 0, opacity: 1 },
  };

  const scrollHandler = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    document && document.addEventListener("scroll", scrollHandler);

    // cleanup
    return () => {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <AnimatePresence mode="wait">
      <div
        className={classNames({
          "main-menu": true,
          "active": menuOpen,
          "loaded": isLoaded,
        })}
        ref={squareRef}
      >
        <div className="signet-wrapper" onClick={() => setMenuOpen(false)}>
          <Link to={currentLanguage === "de" ? "/" : `/${currentLanguage}`}>
            <WuebbenSignet />
          </Link>
        </div>

        <div
          id="toggle-menu"
          tabIndex={0}
          aria-label="toggle-full-screen-menu"
          onClick={toggleFullScreenMenu}
          onMouseEnter={() => setSneak(true)}
          onMouseLeave={() => setSneak(false)}
        >
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
            <line
              x1="1.5"
              y1="10"
              x2="38.5"
              y2="10"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              className="line-1"
            />
            <line
              x1="1.5"
              y1="30"
              x2="38.5"
              y2="30"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              className="line-2"
            />
          </svg>
        </div>
        <nav className="main-navigation" ref={mainNav}>
          <motion.ul
            variants={animationContainer}
            initial="hidden"
            animate={menuOpen ? "show" : "hidden"}
            exit="hidden"
          >
            {!loading &&
              mainMenuQuery.menuByName &&
              mainMenuQuery.menuByName.links.map((item, index) => (
                <motion.li key={index} variants={animationItem}>
                  <Link to={item.url.path} onClick={() => setMenuOpen(false)}>
                    {item.label}
                  </Link>
                </motion.li>
              ))}
            <motion.li
              variants={animationItem}
              className="language-switch-wrapper"
            >
              <LanguageSwitch closeMenu={() => setMenuOpen(false)} />
            </motion.li>
          </motion.ul>
          <motion.div
            className="logo-wrapper"
            initial={{ scale: 0, opacity: 0 }}
            animate={
              menuOpen
                ? { scale: 1, opacity: 1, transition: { delay: 0.5 } }
                : { scale: 0, opacity: 0 }
            }
            exit={{ scale: 0, opacity: 0 }}
          >
            <WuebbenLogo />
          </motion.div>
        </nav>
        <motion.div
          variants={backgroundAnimation}
          initial="hidden"
          animate={
            (menuOpen && "show") ||
            (!menuOpen && !sneak && "hidden") ||
            (!menuOpen && sneak && "sneak")
          }
          exit="hidden"
          className="square"
        />
      </div>
    </AnimatePresence>
  );
};

SimpleNavigation.propTypes = {};

export default SimpleNavigation;
