import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";
import { FormattedMessage } from "react-intl";
import IconPlay from "../../../../teaser-base/media/components/icon-play";

const EmbeddedVideo = ({ content, theme }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const youtubePossibleStrings = ["youtube", "youtu.be"];
  const isYouTube = youtubePossibleStrings.some((string) =>
    content.fieldVideoFileMedia.entity.fieldMediaOembedVideo.includes(string)
  );

  const isVimeo =
    content.fieldVideoFileMedia.entity.fieldMediaOembedVideo.includes("vimeo");

  const youTubeId =
    isYouTube &&
    content.fieldVideoFileMedia.entity.fieldMediaOembedVideo
      ?.replace("https://www.youtube.com/watch?v=", "")
      .replace("https://youtu.be/", "");

  const thumbnail =
    content.fieldVideoFileMedia.entity.fieldCustomThumbnail?.entity
      .fieldMediaImage.style.url ||
    content.fieldVideoFileMedia.entity.thumbnail?.style.url;

  return (
    <div className="youtube-video-wrapper">
      {!showVideo && (
        <>
          <div className="preview">
            {!showVideo && !showInfo && (
              <div
                className="playpause"
                onClick={() => setShowInfo(true)}
                tabIndex={0}
                role={"button"}
                aria-label={"play video"}
              >
                <IconPlay theme={theme} />
              </div>
            )}
            <div className="dsgvo-thumbnail youtube">
              {thumbnail && <img src={thumbnail} alt={"Video Thumbnail"} />}
            </div>
          </div>
          {showInfo && (
            <div className="privacy-info-banner">
              <div className="accept-info">
                {isYouTube && <FormattedMessage id="gdpr.youtube" />}
                {isVimeo && <FormattedMessage id="gdpr.vimeo" />}{" "}
                <Link to={"/page/datenschutzerklaerung"}>
                  <FormattedMessage id="learn_more" />
                </Link>
              </div>
              <a
                tabIndex={0}
                role="button"
                aria-label="accept privacy infos"
                className="button"
                onClick={() => {
                  setShowVideo(true);
                  setShowInfo(false);
                }}
              >
                <FormattedMessage id="okay" />
              </a>
            </div>
          )}
        </>
      )}
      {showVideo && (
        <>
          {isYouTube && (
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube-nocookie.com/embed/${youTubeId}${
                showVideo ? "?autoplay=1" : ""
              }`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              rel={0}
              allowFullScreen
              className="iframe-youtube"
            ></iframe>
          )}
          {isVimeo && (
            <Vimeo
              video={content.fieldVideoFileMedia.entity.fieldMediaOembedVideo}
              responsive
              autoplay
              controls={true}
            />
          )}
        </>
      )}
    </div>
  );
};

EmbeddedVideo.propTypes = {
  theme: PropTypes.string,
  content: PropTypes.shape({
    fieldYoutubeVideoUrl: PropTypes.shape({
      videoId: PropTypes.string.isRequired,
    }),
    fieldVideoFileMedia: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaOembedVideo: PropTypes.string.isRequired,
        thumbnail: PropTypes.object,
        fieldCustomThumbnail: PropTypes.object,
      }),
    }).isRequired,
  }),
  sectionClassNames: PropTypes.string,
};

export default EmbeddedVideo;
