import React from "react";
import PropTypes from "prop-types";
import Video from "./video-components";
import { Link } from "react-router-dom";

/**
 * Render YouTube, Vimeo or local video files.
 */
const ParagraphMedia = ({ content }) => {
  if (content.fieldVideoMode === "teaser") {
    return (
      <section className="paragraph paragraph-media media-teaser">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8 video-wrapper">
              <Video content={content} />
            </div>
            <div className="col-12 col-md-4 video-infos">
              {content.fieldLabel && (
                <p className="headline-s">{content.fieldLabel}</p>
              )}
              {content.fieldTitle && (
                <h3 className="headline-l title">{content.fieldTitle}</h3>
              )}
              {content.fieldAbstract && <p className="abstract">{content.fieldAbstract}</p>}
              {content.fieldLink && (
                <Link className="link" to={content.fieldLink.url.path}>
                  {content.fieldLink.title}
                </Link>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="paragraph paragraph-media media-default">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 video-wrapper">
            <Video content={content} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 col-md-8 offset-md-2">
            <p className="small">{content.fieldAbstract}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphMedia.propTypes = {
  content: PropTypes.shape({
    fieldVideoMode: PropTypes.oneOf([undefined, "default", "teaser"]),
    /**
     * 0 = 100%, 1 = 50%
     */
    fieldGroesse: PropTypes.oneOf(["0", "1"]),
    fieldVimeoVideoUrl: PropTypes.string,
    fieldYoutubeVideoUrl: PropTypes.shape({
      videoId: PropTypes.string,
    }),
    fieldVideoFileMedia: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaVideoFile: PropTypes.shape({
          entity: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    }),
    fieldVideoUrl: PropTypes.string,
    fieldLabel: PropTypes.string,
    fieldTitle: PropTypes.string,
    fieldAbstract: PropTypes.string,
    fieldLink: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.bool,
      }),
    }),
  }),
};

export default ParagraphMedia;
