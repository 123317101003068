import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ParagraphQuote = ({ content }) => {
  const position = classNames({
    "col-12 col-lg-8 offset-lg-2":
      !content.fieldPosition || content.fieldPosition === "center",
    "col-12 col-lg-10 offset-lg-1 col-xl-8":
      content.fieldPosition === "text-aligned",
  });

  return (
    <section className="paragraph paragraph-quote">
      <div className="container">
        <div className="row">
          <div className={position}>
            <blockquote
              className="quote"
              dangerouslySetInnerHTML={{ __html: content.fieldQuote }}
            />
            <p className="author">{content.fieldAuthor}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export const quotePropTypes = {
  fieldQuote: PropTypes.string.isRequired,
  fieldAuthor: PropTypes.string.isRequired,
  fieldPosition: PropTypes.string,
};

ParagraphQuote.propTypes = {
  content: PropTypes.shape(quotePropTypes),
};

export default ParagraphQuote;
