import React from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import SimpleImage from "../../image/simple-image";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

const TeaserNews = (props) => {
  const LinkWrapper = ({ children }) => {
    const sharedProps = {
      className: "flex-wrap",
      onClick: () =>
        props.dispatch(pagerFullPageContextAction(props.pagerFullPage)),
    };

    if (props.item.fieldLinkType === "news") {
      return (
        <Link to={props.item.entityUrl.path} {...sharedProps}>
          {children}
        </Link>
      );
    }
    if (props.item.fieldLinkType === "link" && props.item.fieldLink) {
      if (props.item.fieldLink.url.routed) {
        return (
          <Link to={props.item.fieldLink.url.path} {...sharedProps}>
            {children}
          </Link>
        );
      } else {
        return (
          <a
            className={sharedProps.className}
            href={props.item.fieldLink.url.path}
          >
            {children}
          </a>
        );
      }
    }
    return <div className="flex-wrap">{children}</div>;
  };

  const tags = props.item.fieldSchlagwort;
  const imageData = props.item.fieldTeaserbild?.entity.fieldMediaImage;

  return (
    <article className="node node-teaser teaser-news">
      <EditButton
        adminApp={props.adminApp}
        entityId={props.item.entityId}
        destinationRoute={props.location.pathname}
      />
      <LinkWrapper>
        {props.item.fieldTeaserbild && (
          <SimpleImage
            src={imageData.style.url}
            credit={props.item.fieldTeaserbild?.entity.fieldCredit}
            alt={imageData.alt}
            title={props.item.title}
          />
        )}
        <div className="teaser-body">
          {!!tags.length && (
            <div className="tags-wrapper">
              {tags.map((tag, i) => (
                <div
                  className={`tag ${props.item.fieldTheme || "theme-grey"}`}
                  key={i}
                >
                  {tag.entity.name}
                </div>
              ))}
            </div>
          )}
          <h3 className="headline-m">{props.item.title}</h3>
          {props.item.fieldTeasertext && (
            <div
              className="teaser text"
              dangerouslySetInnerHTML={{
                __html: props.item.fieldTeasertext.processed,
              }}
            />
          )}
        </div>
      </LinkWrapper>
    </article>
  );
};

export const teaserNewsPropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  entityUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
  publishedAt: PropTypes.shape({
    value: PropTypes.number,
    publishedAtOrNow: PropTypes.number,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldTeasertext: PropTypes.shape({
    processed: PropTypes.string,
  }),
  fieldTeaserbild: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
        entity: PropTypes.shape({
          filemime: PropTypes.string,
        }),
      }),
      fieldCredit: PropTypes.string,
    }),
  }),
  fieldTheme: PropTypes.string,
  fieldReferencedContent: PropTypes.object,
});

TeaserNews.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserNewsPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserNews));
