import React from "react";
import { Link } from "react-router-dom";

const Menu = ({ items }) => {
  return (
    <nav className="footer-menu">
      <ul>
        {items.map((item, i) => (
          <li key={i}>
            <Link to={item.url.path}>{item.label}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Menu;
