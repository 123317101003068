import React, { forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";

import teaserNodeQueryFilterTag from "../../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import LoadingIndicator from "../../../../loading-indicator";
import TeaserNews, {
  teaserNewsPropTypes,
} from "../../../../teaser-base/news/teaser-news";
import TeaserPerson, {
  teaserPersonPropTypes,
} from "../../../../teaser-base/person/teaser-person";
import { pagerFullPagesAction } from "../../../../../app-actions";
import { teaserEventPropTypes } from "../../../../teaser-base/event/teaser-event";
import { teaserGeneralPropTypes } from "../../../../teaser-base/general/teaser-general";
import { teaserProjectPropTypes } from "../../../../teaser-base/project/teaser-projekt";
import { useQuery } from "@apollo/client";

const ComponentTeaserlistCarousel = forwardRef((props, sliderRef) => {
  // needed to put mapStateToProps to make forwardRef working
  // forwardRef does not get passed through connect and graphql HOCs
  const currentLanguage = useSelector(
    (reduxStore) => reduxStore.i18n.currentLanguage
  );
  const dispatch = useDispatch();

  // needed to put graphql inside the component for same reason
  const {
    loading,
    error,
    data: nodes,
  } = useQuery(teaserNodeQueryFilterTag, {
    skip: props.nodesConfig === "Manuell",
    variables: {
      limit: props.count ? props.count : 100,
      type: props.type === "all" ? ["news", "person"] : [props.type],
      tag: props.tags.map((item) => item.targetId.toString()),
      filterTagEnabled: props.tags.length > 0,
      language: currentLanguage.toUpperCase(),
    },
  });

  const pushPagerFullPageConfig = () => {
    if (props.pagerFullPage && nodes?.nodeQuery) {
      const pagerFullPagesConfig = {
        id: props.id,
        items:
          props.nodesConfig === "Manuell"
            ? props.manualNodes.map((item) => item.entity)
            : nodes.nodeQuery.entities,
        overviewLink: props.pagerFullPageOverviewLink,
      };

      dispatch(pagerFullPagesAction(pagerFullPagesConfig));
    }
  };

  useEffect(() => {
    // Pager on full screen pages.
    pushPagerFullPageConfig();
    positionHandler(0);
    // Send Node query Ammount to controls
    if (nodes) {
      props.getNodeQueryLength(nodes.nodeQuery.count);
    }

    window?.addEventListener("resize", () => positionHandler(sliderRef.current.innerSlider?.state.currentSlide || 0));
    return () =>
      window?.removeEventListener("resize", () => positionHandler(sliderRef.current.innerSlider?.state.currentSlide || 0));
  }, []);

  // Base Slider Settings, used for person slider
  let slidesToShow = 4;

  let responsive = [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 3.5,
        touchMove: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2.5,
        touchMove: true,
      },
    },
    {
      breakpoint: 786,
      settings: {
        slidesToShow: 1.5,
        touchMove: true,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        touchMove: true,
      },
    },
  ];

  const sliderSettings = {
    adaptiveHeight: false,
    centerMode: false,
    dots: false,
    slidesToScroll: 1,
    slidesToShow,
    infinite: false,
    touchMove: true,
    arrows: false,
    responsive,
    swipeToSlide: true,
    afterChange: (index) => positionHandler(index),
  };

  const positionHandler = (i = 0) => {
    const _slidesToShow = sliderRef.current?.state.breakpoint
      ? responsive.find(
          (item) => item.breakpoint === sliderRef.current?.state.breakpoint
        ).settings.slidesToShow
      : slidesToShow;
    const totalCount =
      props.nodesConfig === "Manuell"
        ? props.manualNodes.length
        : nodes?.nodeQuery?.entities.length;

    props.onChange(i, _slidesToShow, totalCount);
    props.getCurrentSlideId(i);
  };

  if (loading || error) {
    return false;
  }

  return (
    <>
      {(props.nodesConfig === "Manuell" && props.manualNodes.length >= 3) ||
      (props.nodesConfig !== "Manuell" &&
        nodes.nodeQuery &&
        nodes.nodeQuery.entities.length >= 3) ? (
        <>
          {props.nodesConfig === "Manuell" ? (
            <Slider {...sliderSettings} ref={sliderRef}>
              {props.manualNodes.map((item, index) => (
                <React.Fragment key={index}>
                  {(() => {
                    switch (item.entity.entityBundle) {
                      case "news":
                        return (
                          <TeaserNews
                            item={item.entity}
                            pagerFullPage={props.pagerFullPage}
                          />
                        );
                      case "person":
                        return (
                          <TeaserPerson
                            item={item.entity}
                            pagerFullPage={props.pagerFullPage}
                          />
                        );
                      default:
                        return null;
                    }
                  })()}
                </React.Fragment>
              ))}
            </Slider>
          ) : (
            <>
              {nodes.nodeQuery?.entities.length > 0 ? (
                <>
                  <Slider {...sliderSettings} ref={sliderRef}>
                    {nodes.nodeQuery.entities.map((item, index) => (
                      <React.Fragment key={index}>
                        {(() => {
                          switch (item.entityBundle) {
                            case "news":
                              return (
                                <TeaserNews
                                  item={item}
                                  pagerFullPage={props.pagerFullPage}
                                />
                              );
                            case "person":
                              return (
                                <TeaserPerson
                                  item={item}
                                  pagerFullPage={props.pagerFullPage}
                                />
                              );
                            default:
                              return null;
                          }
                        })()}
                      </React.Fragment>
                    ))}
                  </Slider>
                </>
              ) : (
                <LoadingIndicator />
              )}
            </>
          )}
        </>
      ) : null}
    </>
  );
});

ComponentTeaserlistCarousel.propTypes = {
  count: PropTypes.number.isRequired,
  manualNodes: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.oneOfType([
        teaserNewsPropTypes,
        teaserEventPropTypes,
        teaserPersonPropTypes,
        teaserGeneralPropTypes,
        teaserProjectPropTypes,
      ]),
    })
  ),
  id: PropTypes.string.isRequired,
  nodesConfig: PropTypes.oneOf(["Automatisch (chronologisch)", "Manuell"]),
  type: PropTypes.oneOf(["news", "person", "veranstaltung", "all"]),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      targetId: PropTypes.string,
    })
  ),
  pagerFullPageOverviewLink: PropTypes.object,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  onChange: PropTypes.func,
};

ComponentTeaserlistCarousel.displayName = "ComponentTeaserlistCarousel";

export default ComponentTeaserlistCarousel;
