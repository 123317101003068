import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-4 offset-md-4">
          <h1 className="text-center">404</h1>
          <p className="text-center">
            <FormattedMessage id="404" />
          </p>
          <div className="d-flex justify-content-center">
            <Link to="/" className="button">
              <FormattedMessage id="to_startpage" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
