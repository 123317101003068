import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const DateTime = ({ data, detailed = false, locale = "de" }) => {
  const getFormattedDate = (date) => {
    const timeObject = moment.utc(date).locale(locale);

    const dateTime = {
      isMidnight: timeObject.local().format("HHmm") == "0000",
      weekDay: timeObject.local().format("dd"),
      day: timeObject.local().format("DD"),
      month: timeObject.local().format("MMMM").substring(0, 3),
    };

    switch (locale) {
      case "de":
        dateTime.date = `${dateTime.day}. ${dateTime.month}`;
        dateTime.time = timeObject.local().format("HH:mm [Uhr]");
        break;
      case "en":
      default:
        dateTime.date = `${dateTime.month} ${dateTime.day}`
        dateTime.time = timeObject.local().format("hh:mm A");
        break;
    }
    
    return dateTime;
  };

  const startDate = getFormattedDate(data.value);
  const endDate = getFormattedDate(data.endValue);

  return (
    <div className="date-time">
      <div className="meta-date top-line">
        <div className="weekday">{startDate.weekDay},</div>
        <div className="date">
          {startDate.date}
        </div>
        {!startDate.isMidnight && detailed && (
          <span>{startDate.time}</span>
        )}
      </div>
    </div>
  );
};

DateTime.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    endValue: PropTypes.string.isRequired,
  }),
  detailed: PropTypes.bool,
  locale: PropTypes.string
};

export default DateTime;
