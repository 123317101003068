import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { animate } from "framer-motion";
import { createRandomArray, createRandomInt, getGridPoints } from "./helpers";
import PropTypes from "prop-types";
import ClaimTeaserEvent from "./components/claim-teaser-event";
import { Link, Element } from "react-scroll";
import { FormattedMessage } from "react-intl";
import SimpleImage from "../../../image/simple-image";

const ParagraphIntro = (props) => {
  // content
  const { fieldClaimGroups: claimGroups, fieldMainClaim } = props.content;

  // refs
  const claimDestinationRef = useRef();
  const sectionRef = useRef();
  const claimRef = useRef([]);
  const pinnedClaimRef = useRef();

  // state
  const [selected, setSelected] = useState(null);
  const [animations, setAnimations] = useState(false);
  const [grid, setGrid] = useState(
    getGridPoints(
      claimGroups.length,
      sectionRef.current?.clientWidth || 5,
      sectionRef.current?.clientHeight - 100 || 5
    )
  );

  const resetElements = () => {
    const nextAnimations = {};
    claimRef.current.forEach((claim, i) => {
      const { animation, transition } = floatingEffect(i);
      claim.dataset.index = i;

      // start floating animation and map to variable
      let a = animate(claim, animation, transition);
      // store animate instance in state for later control
      nextAnimations[i] = a;
    });
    setAnimations(nextAnimations);
  };

  // returns the floating effect properties needed by framer motion
  const floatingEffect = (i) => ({
    animation: {
      x: createRandomArray(
        createRandomInt(5, 10),
        -50,
        50,
        sectionRef.current.clientWidth * grid[i][0] +
          createRandomInt(-50, 50) +
          sectionRef.current.offsetLeft
      ),
      y: createRandomArray(
        createRandomInt(5, 10),
        -50,
        50,
        (sectionRef.current.clientHeight - 100) * grid[i][1] +
          createRandomInt(-20, 20) +
          0
      ),
      rotate: createRandomArray(createRandomInt(5, 10), -12, 12),
    },
    transition: {
      // randomize the duration to create a more randomized effect
      duration: createRandomInt(30, 60),
      repeat: Infinity,
      ease: "easeInOut",
    },
  });

  // initialize floating animation
  // runs, once the claims got rendered
  // make sure, refs are present
  if (
    claimRef.current?.length &&
    sectionRef.current &&
    grid.length &&
    !animations
  ) {
    // start individual animation for each claim
    resetElements();
  }

  /**
   * Performs the animation to the destination.
   * @param {Element} element
   */
  const mountAnimation = async (element) => {
    const { index } = element.dataset;
    animations[index].stop();
    element.classList.add("active");

    const additionalOffsetX =
      claimDestinationRef.current.clientWidth >= 728
        ? pinnedClaimRef.current?.clientWidth + 12
        : 0;
    const additionalOffsetY =
      claimDestinationRef.current.clientWidth < 728
        ? pinnedClaimRef.current?.clientHeight + 12
        : 0;

    const pinY = 20 + additionalOffsetY;
    const pinX = 20 + additionalOffsetX;

    await animate(
      element,
      { x: pinX, y: pinY, rotate: 0 },
      { type: "spring", duration: 0.3 }
    );
  };

  /**
   * Performs the animation of an element back to its original position in the grid.
   * After completion it starts the floating animation again.
   * @param {Element} element
   */
  const unmountAnimation = async (element) => {
    const { index } = element.dataset;
    const { animation, transition } = floatingEffect(index);

    // remove class "active" from element
    element.classList.remove("active");

    // animate the previous selected item back to its starting point first
    await animate(
      element,
      {
        x: animation.x[0],
        y: animation.y[0],
        rotate: animation.rotate[0],
      },
      {
        type: "spring",
        duration: 0.3,

        // when the element is back on its starting point, start floating animation again
        onComplete: () => {
          const a = animate(element, animation, transition);
          setAnimations((prev) => ({ ...prev, [index]: a }));
        },
      }
    );
  };

  const positionClaim = (e) => {
    setSelected((prev) => {
      // run the unmount animation on the previous element (if it exists)
      if (prev) unmountAnimation(prev);

      // mount the new element if it is a different element
      if (prev !== e.target) {
        mountAnimation(e.target);
        return e.target;
      }

      return null;
    });
  };

  useEffect(() => {
    let animationFrameId;
    let isScrolling = false;

    const updateGrid = () => {
      if (!isScrolling) {
        setGrid(
          getGridPoints(
            claimGroups.length,
            sectionRef.current?.clientWidth,
            sectionRef.current?.clientHeight - 100
          )
        );
      }
      isScrolling = false; // Reset scrolling flag
    };

    const handleResize = () => {
      if (!isScrolling) {
        animationFrameId = window.requestAnimationFrame(() => {
          updateGrid();
          isScrolling = false; // Reset scrolling flag
        });
      }
    };

    const handleScroll = () => {
      isScrolling = true; // Set scrolling flag when scroll happens
    };

    updateGrid(); // Initial grid update

    // Add event listeners for resize and scroll
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("orientationchange", updateGrid);

    // Clean up event listeners and cancel animation frame on component unmount
    return () => {
      window.cancelAnimationFrame(animationFrameId);
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("orientationchange", updateGrid);
    };
  }, []);

  useEffect(() => {
    resetElements();
  }, [grid, sectionRef.current]);

  return (
    <section className="paragraph paragraph-intro">
      <div className="container" ref={sectionRef}>
        <div className="row">
          <div className="col-12" ref={claimDestinationRef} />
        </div>
        <div
          className={classNames({
            "claims-wrapper": true,
            show: !!animations,
          })}
        >
          {fieldMainClaim && (
            <span className="claim-wrapper pinned">
              <div className="claim theme-1" ref={pinnedClaimRef}>
                {fieldMainClaim}
              </div>
            </span>
          )}
          {claimGroups.map((claimGroup, i) => (
            <span
              key={i}
              onClick={claimGroup.entity.fieldMediaType ? positionClaim : null}
              ref={(ref) => (claimRef.current[i] = ref)}
              className={classNames({
                "claim-wrapper": true,
                clickable: !!claimGroup.entity.fieldMediaType,
              })}
            >
              <div
                className={(() => {
                  switch ((Math.pow(i + 2, i + 4 / i + 22) % 3) + 1 || 2) {
                    case 1:
                      return "claim theme-2";
                    case 2:
                      return "claim theme-1-light";
                    case 3:
                      return "claim theme-2-light";
                  }
                })()}
              >
                {claimGroup.entity.fieldClaim}
              </div>
            </span>
          ))}
        </div>
        <div className="row full-height">
          <div className="col-12 col-lg-8 offset-lg-4 d-flex align-items-center align-items-lg-end">
            <div className="media-wrapper">
              {selected &&
                (() => {
                  const { index } = selected.dataset;
                  const selectedClaimGroup = claimGroups[index].entity;
                  const reference =
                    selectedClaimGroup.fieldReferencedContent?.entity;

                  switch (selectedClaimGroup.fieldMediaType) {
                    case "image":
                      if (selectedClaimGroup.fieldImage) {
                        return (
                          <SimpleImage
                            wrapperClassName="claim-image"
                            src={
                              selectedClaimGroup.fieldImage.entity
                                .fieldMediaImage.style.url
                            }
                            alt={
                              selectedClaimGroup.fieldImage.entity
                                .fieldMediaImage.alt
                            }
                            credit={
                              selectedClaimGroup.fieldImage.entity.fieldCredit
                            }
                          />
                        );
                      }
                      return;
                    case "video":
                      if (selectedClaimGroup.fieldVideo) {
                        return (
                          <video
                            className="claim-video"
                            src={
                              selectedClaimGroup.fieldVideo.entity
                                .fieldMediaVideoFile.entity.url
                            }
                            autoPlay={true}
                          ></video>
                        );
                      }
                      return;
                    case "reference":
                      switch (reference.entityBundle) {
                        default:
                          return <ClaimTeaserEvent content={reference} />;
                      }
                    default:
                      return null;
                  }
                })()}
            </div>
          </div>
        </div>
      </div>
      <Link className="scroll-down-info" to="scroll-down-info" smooth={true}>
        <small>
          <FormattedMessage id="scroll_down_info" />
        </small>
        <small className="arrow">↓</small>
      </Link>
      <Element name="scroll-down-info" />
    </section>
  );
};

ParagraphIntro.propTypes = {
  content: PropTypes.shape({
    fieldMainClaim: PropTypes.string,
    fieldClaimGroups: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldClaim: PropTypes.string.isRequired,
          fieldMediaType: PropTypes.string,
          fieldImage: PropTypes.object,
          fieldVideo: PropTypes.object,
          fieldReferencedContent: PropTypes.object,
        }),
      })
    ),
  }),
};

export default ParagraphIntro;
