import moment from "moment";
import { drupalFormat, self } from "../config";

/**
 *
 * @param {Object} datetime
 * @param {String} location
 * @param {String} title
 * @param {String} description
 * @returns
 */
const getEncodedIcalUrl = (datetime, location, title, description = "") => {
  const startTimestamp =
    moment.utc(datetime.value, drupalFormat).format("YYYYMMDDTHHmmss") + "Z";
  const endTimestamp =
    moment.utc(datetime.endValue, drupalFormat).format("YYYYMMDDTHHmmss") + "Z";
  const currentTimestamp = moment().format("YYYYMMDDTHHmmss");

  const icalBaseObject = {
    UID: currentTimestamp + "@" + self,
    CREATED: currentTimestamp,
    DTSTAMP: startTimestamp,
    DTSTART: startTimestamp,
    DTEND: endTimestamp,
    SUMMARY: title,
    DESCRIPTION: description,
    LOCATION: location,
  };

  const icalString =
    [
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      "PRODID:TEST",
      "BEGIN:VEVENT",
      `UID:${icalBaseObject.UID}`,
      `CREATED:${icalBaseObject.CREATED}`,
      `DTSTAMP:${icalBaseObject.DTSTAMP}`,
      `DTSTART:${icalBaseObject.DTSTART}`,
      `DTEND:${icalBaseObject.DTEND}`,
      `SUMMARY:${icalBaseObject.SUMMARY}`,
      `DESCRIPTION:${icalBaseObject.DESCRIPTION}`,
      `LOCATION:${icalBaseObject.LOCATION}`,
      "END:VEVENT",
      "END:VCALENDAR",
    ].join("\r\n") + "\r\n";

  return "data:text/calendar," + encodeURIComponent(icalString);
};

export default getEncodedIcalUrl;
