const appReducerDefaultState = {
    adminApp: false,
    pagerFullPageContextId: false,
    pagerFullPages: [],
    initialLoad: true,
  },
  appReducer = (state = appReducerDefaultState, action) => {
    switch (action.type) {
      case "ADMIN_APP": {
        return Object.assign({}, state, { adminApp: action.adminApp });
      }
      case "PAGER_FULL_PAGE": {
        // Remove if they are pushed twice (or after query finish).
        const pagerFullPages = state.pagerFullPages.filter(
          (config) => config.id !== action.config.id
        );

        return Object.assign({}, state, {
          pagerFullPages: [...pagerFullPages, action.config],
        });
      }
      case "PAGER_FULL_PAGE_CONTEXT_ID": {
        return Object.assign({}, state, { pagerFullPageContextId: action.id });
      }
      case "INITIAL_LOAD": {
        return Object.assign({}, state, { initialLoad: action.initialLoad });
      }

      default:
        return state;
    }
  };

export { appReducerDefaultState, appReducer };
