import React from "react";
import PropTypes from "prop-types";
import MediaContent from "./components/media-content";

const Media = (props) => {
  return <MediaContent entityUrl={props.location.pathname} />;
};

Media.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default Media;
