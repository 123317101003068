import React from "react";
import Appointment from "./components/appointment";
import PropTypes from "prop-types";

const ParagraphSchedule = ({ content }) => {
  return (
    <section className="paragraph paragraph-schedule">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-10 offset-lg-1">
            <ul>
              {content.fieldAppointments.map((appointment, i) => (
                <li key={i}>
                  <Appointment content={appointment.entity} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphSchedule.propTypes = {
  content: PropTypes.shape({
    fieldAppointments: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          title: PropTypes.string,
          fieldDate: PropTypes.shape({
            value: PropTypes.string,
            date: PropTypes.string,
          }),
          fieldSeason: PropTypes.string,
          fieldText: PropTypes.shape({
            processed: PropTypes.string,
          }),
        }),
      })
    ),
  }),
};

export default ParagraphSchedule;
